import React from "react";
import ConfirmOTP from "../components/confirm-otp/ConfirmOTP";

export default class ConfirmOTPPage extends React.Component {
  render() {
    return (
      <section id="confirmOTPPage" className="container mt-5">
        <ConfirmOTP />
      </section>
    );
  }
}
