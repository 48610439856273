import React from "react";
import FAQ from "../components/faq/faq";
import Banner from "../components/shared/Banner";
import imageFAQ from "../assets/faq.jpg";
export default class FAQPage extends React.Component {
  componentWillMount(){
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  
  render() {
    const content = "Nếu bạn chia sẻ niềm đam mê của chúng tôi trong việc mang tới một tương lai tốt đẹp hơn,chúng tôi muốn được lắng nghe bạn.";
    return (
      <React.Fragment>
        <Banner image={imageFAQ} 
        image_class="faq"
        title="Tìm hiểu về chúng tôi" 
        content={content} 
        className="h1-title"/>
        <section id="faq" className="container mt-8">
          <FAQ />
        </section>
      </React.Fragment>
    );
  }
}
