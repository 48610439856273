import React from "react";
import Survey from "../components/survey/Survey";

export default class SurveyPage extends React.Component {

    render() {
        let status = this.props.match.params.status;
        let surveyId = this.props.match.params.sid;
        let userId = this.props.match.params.uid;

        return (
            <section id="survey" className="container mt-8">
                <Survey 
                    status={status} 
                    surveyId={surveyId}
                    userId={userId}
                ></Survey>
            </section>
        );
    }
}