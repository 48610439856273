import React from "react";

export function Content() {
  return (
    <div className="content">
      <h1>Tại sao nên tham gia Cimigo Health?</h1>
      <p>
       
Thành viên của Cimigo Health có cơ hội cập nhật thông tin sản phẩm & dịch vụ y tế mới, chia sẻ ý kiến đóng góp phát  triển sản phẩm, dịch vụ và tăng thu nhập cá nhân. Việc tham gia khảo sát, phỏng vấn luôn được tổ chức vào thời gian thuận tiên. Các thành viên được quyền chọn lựa tham gia các khảo sát phù hợp với chuyên môn và kinh nghiệm của mình. Có thể hủy đăng ký thành viên bất cứ lúc nào.
      </p>

      <h1>Về Cimigo</h1>
      <p>
      Cimigo là một công ty nghiên cứu và tư vấn hàng đầu, với hơn 200 chuyên gia nghiên cứu hoạt động trên toàn khu vực Châu Á - Thái Bình Dương. Chúng tôi tự hào là một chuyên gia hàng đầu Châu Á, đầu tư mạnh mẽ vào khu vực này và đặt khách hàng là trung tâm phát triển kinh doanh.
      </p>
      <p>Cimigo được tin tưởng trao quyền làm đại diện, phân tích, và mang tiếng nói của khách hàng đến nhiều công ty. Các chuyên gia tư vấn của Cimigo đem đến một cái nhìn độc đáo và tươi mới cho quá trình ra quyết định của các công ty. Cimigo cung cấp một loạt các dịch vụ từ việc nghiên cứu toàn diện theo yêu cầu và tư vấn, đến nghiên cứu thực nghiệm đáng tin cậy trên toàn Chấu Á, và các giải pháp thuê làm bên ngoài (outsource) hiệu quả cho lĩnh vực nghiên cứu.</p>
      <p>Cimigo cam kết truyền cảm hứng và  giúp cho khách hàng của chúng tôi đưa ra những quyết định tối ưu nhất. </p>
    </div>
  );
}
