import React from "react";
import ContactForm from "./ContactForm";
import * as actionContact from "../../actions/contact-action";
import SnackBar from "../shared/snackBar";
import { destroy, isPristine } from "redux-form";
import { connect } from "react-redux";

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      snackBar: {
        message: "",
        variant: "",
        open: false
      }
    };

    this.contactSubmit = this.contactSubmit.bind(this);
    this.cancelSnackBar = this.cancelSnackBar.bind(this);
  }

  cancelSnackBar = () => {
    this.setState({
      snackBar: {
        ...this.state.snackBar,
        open: false
      }
    });
  };
  contactSubmit(data) {
    actionContact.contact(data).then(
      res => {
        this.props.destroy("ContactForm");
        this.setState({
          snackBar: {
            message: "Gửi email thành công",
            variant: "success",
            open: true
          }
        });
      },
      error => {
        this.setState({
          snackBar: {
            message: error,
            variant: "error",
            open: true
          }
        });
      }
    );
  }

  render() {
    return (
      <div className="mt-10 mb-5">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col col-sm-6 col-md-6 col-lg-5 col-xl-5">
            <h3 className="uppercase text-center">liên hệ</h3>
            <div>
              <ContactForm submitCb={this.contactSubmit} />
            </div>
          </div>
        </div>
        <SnackBar
          message={this.state.snackBar.message}
          variant={this.state.snackBar.variant}
          open={this.state.snackBar.open}
          handleCloseCb={this.cancelSnackBar}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ContactForm: state.form.ContactForm,
    pristine: isPristine("ContactForm")(state)
  }
};

export default connect(mapStateToProps, { destroy })(Contact);
