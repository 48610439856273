import React from "react";
import { Field, reduxForm } from "redux-form";
import { DPInput } from "../shared/form/DPInput";

const ConfirmOTPForm = props => {
    const { handleSubmit, submitCb, indicator } = props;
    return (
        <form onSubmit={handleSubmit(submitCb)}>
          <Field name="otp" component={DPInput} label="Mã OTP" type="text" className="material-ui-form" />
         
          <button
            className="action-btn blue register-btn btn-block mt-4"
            type="submit"
            disabled={indicator}
          >
            Xác nhận
          </button>
        </form>
      );
};

const validate = values => {
    const errors = {}
    const requiredFields = [
      'otp',
    ]
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Không được bỏ trống'
      }
    })
    return errors
  }
  
export default reduxForm({
    form: "ConfirmOTPForm",
    validate
  })(ConfirmOTPForm);
  