import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import authService from 'services/auth-service';

const ProtectedRoute = (props) => {
  const {component: Component, ...rest} = props;


  return (
    <Route
      {...rest}
      render={props => {
        if (!authService.isAuthenticated()) {
          return (
            <Redirect
              to={{
                pathname: '/dang-nhap',
                state: {sourceUrl: props.location.pathname},
              }}
            />
          );
        } else {
            const { user } = rest;
            if(!_isEmpty(user) && !_isEmpty(user.invitation_code)){
                const { code_type } = user.invitation_code;
                const { doctor_profile } = user;

                if(!_isEmpty(code_type)){
                    if(_isEmpty(doctor_profile) && code_type !== 'interview'){
                        return <Redirect
                          to={{
                            pathname: '/bo-sung-thong-tin',
                          }}
                        />
                    }
                }
            }
          return <Component {...props} {...rest} />;
        }
      }}
    />
  );
}

function mapStateToProps(state) {
  return {
      user: state.user.data
  }
}
export default connect(mapStateToProps)(ProtectedRoute);
