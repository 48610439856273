import React from "react";
import Modal from "react-responsive-modal";

export function RegisterModal(props){
    const { open, closeModal } = props;
    return(
        <Modal open={open} onClose={closeModal} center>
             <h2>Đăng ký thành công</h2>
          <p>
            Cám ơn bạn đã đăng ký thông tin tại Cimigo Health.
          </p>
        </Modal>
    );
}
/*
  "private": true,
  "proxy": "https://healthapi.cimigo.com",
  */
