import axiosService from '../services/axios.service';

const axiosInstance = axiosService.getBaseInstance();

export const contact = (data) =>{
    return axiosInstance.post('/contacts', data).then(
        res => res.data,
        err => Promise.reject(err.response.data.message)
    )
}
