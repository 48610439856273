import { connect } from 'react-redux';

import RegisterCode from './RegisterCode';
import * as refCodeActions from '../../actions/ref-code-action';
import * as authActions from '../../actions/auth-action';

const map_state_to_props = (state) => {
    return {
        is_code_valid: state.refCode.isValid,
        ref_code: state.refCode.code,
        code_type: state.refCode.codeType,
        error: state.refCode.error,
        isAuth: state.auth.isAuth
    }
}

const actions_to_props = {
    checkRefCode: refCodeActions.checkRefCode,
    socialLogin: authActions.socialLogin

}

export default connect(map_state_to_props, actions_to_props)(RegisterCode);

