import React from "react";
import { Field, reduxForm } from "redux-form";
import { DPInput } from "../shared/form/DPInput";

const ResetPasswordForm = props => {
  const { handleSubmit, submitCb } = props;
  return (
    <form onSubmit={handleSubmit(submitCb)}>
       <Field
        name="newPassword"
        type="password"
        label="Mật khẩu mới"
        className="material-ui-form"
        component={DPInput}
      />
      <Field
        name="newPasswordConfirmation"
        type="password"
        label="Nhập lại mật khẩu mới"
        className="material-ui-form"
        component={DPInput}
      />
      <button
        className="action-btn blue register-btn btn-block mt-4"
        type="submit"
      >
        Xác nhận
      </button>
    </form>
  );
};

const validate = values => {
    const errors = {};
  
    if (!values.newPassword) {
      errors.newPassword = "Vui lòng nhập mật khẩu mới";
    }
  
    if (values.newPassword !== values.newPasswordConfirmation) {
      errors.newPasswordConfirmation = "Mật khẩu xác nhận chưa chính xác";
    }
  
    return errors;
  };
  
  
export default reduxForm({
  form: "ResetPasswordForm",
  validate
})(ResetPasswordForm);
