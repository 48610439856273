import {
    CLEAR_REFCODE,
    CHECK_REFCODE_REQUEST,
    CHECK_REFCODE_SUCCESS,
    CHECK_REFCODE_FAILURE
} from './types';

import axiosService from '../services/axios.service';

const axiosInstance = axiosService.getBaseInstance();

const checkRefCodeSuccess = (refCode) => {
    return {
        type: CHECK_REFCODE_SUCCESS,
        refCode
    }
}

const checkRefCodeFailure = (error) => {
    return {
        type: CHECK_REFCODE_FAILURE,
        error
    }
}

const checkRefCodeRequest = () => {
    return {
        type: CHECK_REFCODE_REQUEST
    }
}

export const clearRefCode = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REFCODE
    });
  }
}

export const checkRefCode = (code) => {
    return (dispatch) => {
        checkRefCodeRequest();

        axiosInstance
            .get('/ref-code/check/'+code)
            .then(res => res.data)
            .then((refCode) => {
                dispatch(checkRefCodeSuccess(refCode));
            })
            .catch((err) => {
                if(err.response){
                dispatch(checkRefCodeFailure(err.response.data));
                    }
            });
    }
}
