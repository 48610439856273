import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionAuth from '../../actions/auth-action';

class LockUser extends React.Component{
    token;

    // eslint-disable-next-line
    constructor(){
        super();
    }

    componentWillMount(){
        const { token } = this.props;
        this.token = token;
        
        this.lockUser();
    }

    logout(){
        this.props.dispatch(actionAuth.logout());
    }

    lockUser() {
        if(this.token && this.token !== ''){
            actionAuth.lockUser(this.token).then(
                res => {
                    console.log(res);
                    this.logout();
                }
            )
        }
    }
    render(){
        return(
            <React.Fragment>
                <div className="text-center mt-7">
                <h2>Tài khoản của bạn đã bị khóa</h2>
                <h4>Bấm vào đây để trở về <Link to="/">trang chủ</Link></h4>
                </div>
               
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return{
        auth: state.auth
    }
}

export default connect(mapStateToProps)(LockUser);