import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class Footer extends React.Component {
  render() {
    const { pathname } = this.props.location;
    var url = null,
      name = null;
    var flag = false;

    if (pathname === "/thong-tin-dieu-khoan") {
      flag = true;
      url = "https://www.freepik.com/free-photos-vectors/background";
      name = "Freepik";
    } else if (pathname === "/chinh-sach-bao-mat") {
      flag = true;
      url = "https://www.freepik.com/free-photos-vectors/technology";
      name = "Pressfoto";
    } else if (pathname === "/") {
      flag = true;
      url = "https://www.freepik.com/free-photos-vectors/background";
      name = "Jcomp";
    } else {
      flag = false;
      url = null;
      name = null;
    }
    return (
      <footer id="footer">
        <section className="copyright ptb-30 footerContent">
          <div className="container">
            <ul className="social">
              <li>
                <a
                  href="https://twitter.com/CimigoVietnam/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-twitter fa-lg" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/Cimigo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin fa-lg" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/CimigoVietnam/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook-f fa-lg" />
                </a>
              </li>
            </ul>

            <div className="spacer-15" />

            <p>© {new Date().getFullYear()} Copyright - Cimigo Health. All Rights Reserved.</p>
            {flag === true && (
              <p>
                <a className="none-weight" href={url}>
                  Photo created by {name} - www.freepik.com
                </a>
              </p>
            )}

            <nav>
              <span>
                <Link to="/lien-he">Liên hệ</Link>
              </span>
              <span>
                <Link to="/chinh-sach-bao-mat">
                  Chính sách bảo mật
                </Link>
              </span>
              <span>
                <Link to="/thong-tin-dieu-khoan">
                  Thông tin điều khoản
                </Link>
              </span>
              <span>
                <Link to="/hoi-dap">
                  Tìm hiểu Cimigo Health
                </Link>
              </span>
            </nav>
          </div>
        </section>
      </footer>
    );
  }
}

export default withRouter(Footer);
