import React from "react";
import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SnackBar from "../shared/snackBar";
import * as actionAuth from "../../actions/auth-action";
import SocialLogin from "../social-login/SocialLogin";

class Login extends React.Component {

  constructor() {
    super();

    this.state = {
      snackBar: {
        message: "",
        variant: "",
        open: false
      }
    };
    this.loginUser = this.loginUser.bind(this);
    this.cancelSnackBar = this.cancelSnackBar.bind(this);
  }

  loginUser(userData) {
    this.props.login(userData);
  }

  cancelSnackBar = () => {
    this.props.auth.errors = [];
    
    this.setState({
      snackBar: {
        ...this.state.snackBar,
        open: false
      }
    });
  };

  componentDidUpdate(prevProps) {
    const { isAuth, errors } = this.props.auth;

    if (!isAuth && errors) {
      if (prevProps.auth.errors !== errors) {
        this.setState({
          snackBar: {
            message: errors,
            variant: "error",
            open: true
          }
        });
      }
    }
  }

  render() {
    const { isAuth, errors } = this.props.auth;
    const { sourceUrl } = this.props;

    if (isAuth) {
      if(!sourceUrl)
        return <Redirect to={{ pathname: "/bang-dieu-khien" }} />;
      else return <Redirect to={{ pathname: sourceUrl}} />;
    }

    return (
      <div className="text-center mb-5">
        <div className="col-lg-4 col-md-6 center-block">
          <div className="text-left mb-3">
            <SocialLogin onLogin={this.props.socialLogin} title={'Chọn hình thức đăng nhập'} />
          </div>
          <div className="sep">
            <strong>HOẶC</strong>
          </div>
          <div className="text-left mb-3">
            <LoginForm submitCb={this.loginUser} errors={errors} />
          </div>
          <Link to="/quen-mat-khau">Quên mật khẩu ?</Link>
          {errors.length > 0 && (
            <SnackBar
              message={this.state.snackBar.message}
              variant={this.state.snackBar.variant}
              open={this.state.snackBar.open}
              handleCloseCb={this.cancelSnackBar}
            />
          )}
        </div>
      </div>
    );
  }
}

const actionsToProps = {
  socialLogin: actionAuth.socialLogin,
  login: actionAuth.login
}
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps, actionsToProps)(Login);
