import React from "react";
import { EditableComponent } from "./EditableComponent";

export class EditableInput extends EditableComponent{
 

    renderLabel(){
        const { label } = this.props;
        if(label !== undefined){
            return(
                <label><strong>{label}&nbsp;</strong></label>
            );
        }
        
    }

    renderComponentView(){
        const {value, isActive} = this.state;
        const {className, isLoading} = this.props;

        if(isActive){
            return(
                <React.Fragment>
                    <input onChange={(event) => this.handlChange(event)}
                            value={value}
                            className={className}/>
                    <button onClick={() => this.update()} 
                            className="btn btn-primary btn-editable" 
                            type="button">
                            <i className="far fa-save"></i>
                    </button>
                    <button onClick={() => this.disableEdit()} 
                            className="btn btn-danger btn-editable" 
                            type="button">
                            <i className="fas fa-times"></i>
                    </button>
                </React.Fragment>
            );
        }

        if(isLoading){
            return (
                <React.Fragment>
                    <div className="sp sp-circle"></div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <span>{value}</span>
                <button onClick={() => this.enableEdit()} 
                        className="btn-dp btn-editable" 
                        type="button">
                            <i className="far fa-edit iconSize14"></i>
                </button>
            </React.Fragment>
          
        );

    }

    render(){
        return(
            <div id="editableComponent" className="mb-2">
                {this.renderLabel()}
                {this.renderComponentView()}
            </div>
        );
    }
}
