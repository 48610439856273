import axiosService from '../services/axios.service';

const axiosInstance = axiosService.getInstance();

export const uploadImage = (image, type) => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('type', type);

    return axiosInstance.post('/upload/image', formData)
        .then(json =>{
            return json.data.image_url;
        })
        .catch(({response}) => Promise.reject(response.data.message))
}

export const uploadAvatar = (image) => {
    const formData = new FormData();
    formData.append('image', image);

    return axiosInstance.post('/upload/avatar', formData)
        .then(json =>{
            return json.data.image_url;
        })
        .catch(({response}) => Promise.reject(response.data.message))
}