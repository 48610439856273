import React from "react";
import { connect } from "react-redux";
import * as actionSurvey from "../../actions/survey-action";
import { Link } from 'react-router-dom';
class Survey extends React.Component{


    surveyComplete(surveyId){
        actionSurvey.surveyComplete(surveyId).then(
            res => {
                //console.log(res);
            }
        )
    }

    render(){
        const { surveyId, status } = this.props;
        //var p = param.split("-");
        // console.log(`sid: ${surveyId}`);
        // console.log(`uid: ${userId}`);
        // console.log(`status: ${status}`);
        var str = "";
        if(status === "complete"){
            str = "Cám ơn bạn đã hoàn thành khảo sát này";
        }else if(status === "conducted"){
            str = "Bạn đã hoàn thành khảo sát này rồi"
        }

        return(
           <React.Fragment>
               <div className="text-center mt-15 mb-20">
               <h2>{str}</h2>
               <h4>Vui lòng nhấn <Link to="/bang-dieu-khien">vào đây</Link> để trở về trang danh sách khảo sát</h4>
                {this.surveyComplete(surveyId)}
               </div>
                
           </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.data,
    }

}

export default connect(mapStateToProps)(Survey)