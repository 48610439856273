import React from "react";

import { Field, reduxForm } from "redux-form";
import { DPInput } from "../shared/form/DPInput";
import { DPSelect } from "../shared/form/DPSelect";

const RegisterForm = props => {
  const {
    handleSubmit,
    submitCb,
    cities,
    specialists,
    doctorTitles,
    doctorAcademics
  } = props;

  return (
    <form onSubmit={handleSubmit(submitCb)}>
      <Field
        name="fullname"
        type="text"
        component={DPInput}
        label="Họ và tên"
        className="material-ui-form"
      />
      <Field
        name="email"
        component={DPInput}
        type="text"
        label="Email"
        className="material-ui-form"
      />
      <Field
        name="phone"
        component={DPInput}
        type="text"
        label="Số điện thoại"
        className="material-ui-form"
      />
      <Field
        name="hospital"
        type="text"
        component={DPInput}
        label="Nơi làm việc"
        className="material-ui-form"
      />
      <Field
        options={cities.sort(function(a, b) {
          return a["value"] - b["value"];
        })}
        name="city"
        label="Tỉnh/Thành phố"
        className="material-ui-form py-1"
        component={DPSelect}
      />
      <Field
        options={specialists.sort(function(a, b) {
          return a["value"] - b["value"];
        })}
        name="specialist"
        label="Chuyên khoa"
        className="material-ui-form py-1"
        component={DPSelect}
      />
      <Field
        options={doctorTitles.sort(function(a, b) {
          return a["value"] - b["value"];
        })}
        name="doctor_title"
        label="Chức danh"
        className="material-ui-form py-1"
        component={DPSelect}
      />
      <Field
        options={doctorAcademics.sort(function(a, b) {
          return a["value"] - b["value"];
        })}
        name="doctor_academic"
        label="Học hàm"
        className="material-ui-form py-1"
        component={DPSelect}
      />
       
      <Field name="is_subscribed" id="is_subscribed" component="input" type="checkbox" />
      &nbsp;
      <label id="is_subscribed" htmlFor="is_subscribed" htmlstyle="display: inline"> Đồng ý nhận email từ Cimigo Health</label>
      <button
        className="action-btn blue register-btn mt-3 btn-block"
        type="submit"
      >
        Đăng ký
      </button>
    </form>
  );
};

const validate = values => {
  const errors = {};
  const requiredFields = [
    "fullname",
    "phone",
    "hospital",
    "city",
    "specialist",
    "doctor_title",
    "doctor_academic"
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = "Không được bỏ trống";
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Địa chỉ email không hợp lệ";
  }
  if (values.phone && !/^[0-9]{10}$/i.test(values.phone)) {
    errors.phone = "Số điện thoại không hợp lệ";
  }
  if (values.city && values.city === "null") {
    errors.city = "Vui lòng chọn thành phố";
  }
  if (values.specialist && values.specialist === "null") {
    errors.specialist = "Vui lòng chọn chuyên khoa";
  }
  if (values.doctor_title && values.doctor_title === "null") {
    errors.doctor_title = "Vui lòng chọn chức danh";
  }
  if (values.doctor_academic && values.doctor_academic === "null") {
    errors.doctor_academic = "Vui lòng chọn học hàm";
  }

  return errors;
};

export default reduxForm({
  form: "RegisterForm",
  validate,
  initialValues: { status: 1, city: null, specialist: null }
})(RegisterForm);
