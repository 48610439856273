import React from "react";
import * as actionUploadImage from "../../../actions/uploadImage-action";
import { formatBytes } from "../../../helpers/formatBytes";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import classNames from "classnames";

const styles = {
  card: {
    maxWidth: "100%"
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: "cover",
  },

  heading: {
    fontFamily: "Arial",
    fontSize: "1.1em",
    display: "inline-block",

    height: "25px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  nameHeading:{
    fontWeight: 'bold',
    marginTop: '10px',
    marginBottom: 0,
  },
  sizeHeading: {
    fontWeight: 'normal',
    fontStyle: 'italic',
    marginBottom: '5px',
  }
};

class DPFileUpload extends React.Component {
  constructor() {
    super();

    this.setupReader();

    this.state = {
      selectedFile: undefined,
      imageBase64: "",
      pending: false,
      status: "INIT",
      error: ""
    };

    this.onChange = this.onChange.bind(this);
  }

  setupReader() {
    this.reader = new FileReader();
    this.reader.addEventListener("load", event => {
      const imageBase64 = event.target.result;
      this.setState({ imageBase64: imageBase64 });
    });
  }

  onChange(event) {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      this.setState({ selectedFile });
      this.reader.readAsDataURL(selectedFile);
    }
  }

  onError(error) {
    this.setState({ pending: false, status: "FAIL", error: error });
  }

  onSuccess(uploadImage) {
    const {
      input: { onChange }
    } = this.props;
    this.setState({ pending: false, status: "OK", error: "" });
    onChange(uploadImage);
  }
  uploadImage() {
    const { selectedFile } = this.state;

    if (selectedFile) {
      this.setState({ pending: true, status: "INIT" });

      actionUploadImage.uploadImage(selectedFile, "images").then(
        uploadImage => {
          this.onSuccess(uploadImage);
        },
        error => {
          this.onError(error);
        }
      );
    }
  }

  renderSpinningCircle() {
    const { pending } = this.state;

    if (pending) {
      return (
        <div className="img-loading-overlay">
          <div className="img-spinning-circle" />
        </div>
      );
    }
  }

  renderImageStatus() {
    const { status, error } = this.state;
    if (status === "OK") {
      return (
        <div className="alert alert-success"> Tải hình ảnh thành công </div>
      );
    }

    if (status === "FAIL") {
      return <div className="alert alert-danger"> {error} </div>;
    }
  }

  ImgMediaCard(classes, imageBase64, selectedFile) {
    return (
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            className={classes.media}
            image={imageBase64}
            title="Contemplative Reptile"
          />
          <CardContent>
            <h4
              className={classNames(
                classes.heading,
                classes.nameHeading
              )}
            >
              Tên: {selectedFile.name}
            </h4>
            <br></br>
            <h4  className={classNames(
                classes.heading,
                classes.sizeHeading
              )}>
              Kích thước: {formatBytes(selectedFile.size)}
            </h4>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="large"
            color="primary"
            onClick={() => this.uploadImage()}
          >
            Tải hình ảnh
          </Button>
        </CardActions>

        {this.renderSpinningCircle()}
      </Card>
    );
  }
  render() {
    const {
      classes,
      meta: { touched, error }
    } = this.props;
    const { selectedFile, imageBase64 } = this.state;

    return (
      <div className="img-upload-container">
        <label className="img-upload btn btn-dp-primary">
          <span className="upload-text">Chọn một hình ảnh</span>
          <input
            type="file"
            accept=".jpg, .png, jpeg"
            onChange={this.onChange}
          />
        </label>
        <div className="mt-3">
            {touched &&
            (error && <div className="alert alert-danger">{error}</div>)}

            {imageBase64 && this.ImgMediaCard(classes, imageBase64, selectedFile)}
            {this.renderImageStatus()}
        </div>
       
      </div>
    );
  }
}

export default withStyles(styles)(DPFileUpload);