import React from "react";
import { connect } from "react-redux";
import * as actionAuth from "../../../actions/auth-action";
import * as actionUser from "../../../actions/user-action";
import { Redirect } from "react-router-dom";
import { SURVEY_URL } from '../../../core/system.constants';

class SurveyGizmo extends React.Component {

    componentWillMount() {
        this.props.dispatch(actionAuth.getUser());
        this.props.dispatch(actionUser.getProfiles());
    }

    renderLinkSurveyGizmo(isCompleteProfile, externalId, alias_lst) {
        if (!isCompleteProfile || isCompleteProfile === undefined) {
            const link = `${SURVEY_URL}?notAnswered=${alias_lst}&external_id=${externalId}`;
            window.location = link;
        }

    }
    render() {
        const { externalId, user, profiles } = this.props;

        var alias_lst = "";
        if (profiles.length !== 0) {

            profiles.forEach(p => {
                alias_lst = alias_lst + p + "_";
            });
        }

        if (user._id) {
            if (user.external_id !== externalId) {
                return <Redirect to={{ pathname: "/" }} />;
            }
            else {
                return (
                    <React.Fragment>
                        <h1 className="text-center">Đang điều hướng đến bảng câu hỏi...</h1>
                         {this.renderLinkSurveyGizmo(user.is_complete_profile, externalId, alias_lst.slice(0, -1))}
                    </React.Fragment>
                );
            }

        } else {
            return (
                <React.Fragment>
                    <div className="text-center">
                        <h1>Vui lòng chờ...</h1>
                    </div>
                </React.Fragment>
            );
        }


    }
}

function mapStateToProps(state) {
    return {
        user: state.user.data,
        profiles: state.profiles.data
    }

}

export default connect(mapStateToProps)(SurveyGizmo);