import React from "react";

export default class Privacy extends React.Component {
  render() {
    return (
      <div>
           <br></br>
        <br></br>
        <p>
          Cimigo Health là một sáng kiến từ công ty nghiên cứu thị trường
          Cimigo. Cimigo (Cimigo Health) thu thập thông tin với mục đích duy
          nhất là dùng để nghiên cứu thị trường. Nghiên cứu thị trường là việc
          thu thập và diễn giải các chi tiết có được từ người tiêu dùng, các
          công ty và xã hội, giúp người sử dụng dịch vụ này đưa ra quyết định
          tốt hơn và hiệu quả hơn liên quan đến các công việc như tiếp thị, phát
          triển sản phẩm và xã hội. Việc bạn tham gia các cuộc khảo sát tại
          Cimigo Health sẽ giúp cho các công ty phát triển và cải thiện những
          sản phẩm và dịch vụ của mình và tất nhiên thông tin của các bạn vẫn
          được bảo mật.
        </p>
        <p>
        Với việc tôn trọng quyền riêng tư của cá nhân, Cimigo sẽ không bao giờ
          chia sẻ, trao đổi hoặc bán thông tin của thành viên cho một bên thứ ba
          nào khác trong bất kỳ trường hợp nào.
        </p>
        <ul>
          <li>Cimigo Health sẽ xử lý dữ liệu của bạn như thế nào?</li>
          <li>Tuyên bố bảo mật áp dụng đối với những ai?</li>
          <li>Loại thông tin nào Cimigo thu thập từ những người tham gia?</li>
          <li>Cimigo sẽ sử dụng những thông tin này như thế nào?</li>
          <li>Bạn có thể truy cập thông tin cá nhân bằng cách nào?</li>
          <li>Bạn có thể ngừng sự tham gia của mình bằng cách nào?</li>
          <li>
            Làm thế nào để chúng tôi thông báo cho bạn biết về những thay đổi
            trong chính sách bảo mật?
          </li>
          <li>Các tổ chức và các quy định mà chúng tôi tuân theo</li>
        </ul>
        <h4>1. Cimigo Health sẽ xử lý dữ liệu của bạn như thế nào?</h4>
        <p>
          Những câu trả lời của bạn cho các cuộc khảo sát, nghiên cứu và trên
          trang web của chúng tôi sẽ được bảo mật. Trường hợp ngược lại chỉ xảy
          ra khi và chỉ khi bạn đồng ý cho phép cung cấp thông tin. Trong một số
          ít trường hợp mà chúng tôi yêu cầu bạn cho phép để chuyển dữ liệu dưới
          một hình thức nào đó mà nó buộc bạn phải xác nhận danh tính, khi đó
          chúng tôi sẽ đảm bảo rằng các thông tin sẽ chỉ được sử dụng cho các
          mục đích đã nêu. Chúng tôi sẽ không gửi cho bạn những lá thư thương
          mại không được yêu cầu, cũng không đưa email của bạn cho những người
          khác vì mục đích trên. Tuy nhiên các cuộc điều tra khảo sát được thực
          hiện hầu như ẩn danh tất cả thông tin như tên, địa chỉ, email của bạn
          không bao giờ được truyền ra cho các công ty khác hoặc người khác.
          Điều này áp dụng cho tất cả các thành viên tham gia vào những cuộc
          khảo sát của chúng tôi cũng như các cuộc nghiên cứu vì đây là yêu cầu
          pháp lý cho từng cuộc khảo sát điều tra.
        </p>
        <h4>2. Tuyên bố bảo mật áp dụng đối với những ai?</h4>
        <p>
          Chính sách bảo mật này áp dụng cho tất cả những người nào đang tham
          gia hoặc đã và sẽ tiếp tục tham gia vào những nghiên cứu của Cimigo
          Health hay Cimigo:
        </p>
        <ul>
          <li>
            Những người đã đăng ký tham gia thông qua hình thức đăng ký trên
            trang web của Cimigo Health
          </li>
          <li>
            Những người tham gia được mời qua điện thoại hoặc tuyển dụng trực
            tiếp bởi nhân viên Cimigoqua.
          </li>
          <li>
            Những người đã từng tham gia những cuộc khảo sát trong bất kỳ hình
            thức nào khác không phải những cái liệt kê ở trên.
          </li>
        </ul>
        <h4>3. Loại thông tin nào Cimigo thu thập từ những người tham gia?</h4>
        <p>
          Thông tin như tên, địa chỉ email, số điện thoại, chuyên
          khoa, nơi làm việc như là những thông tin được xếp
          vào loại “thông tin cá nhân có thể nhận dạng” vì những thông tin này
          có thể được sử dụng gián tiếp hoặc trực tiếp để nhận diện các bạn.
        </p>

        <p>
        Những thông tin này được bảo vệ chặt chẽ bởi chính sách bảo mật của
          chúng tôi. Chúng tôi sẽ không bao giờ đưa ra, bán, cho thuê hoặc trao
          đổi thông tin cá nhân của bạn cho một bên thứ ba hoặc chia sẻ thông
          tin cho bên thứ ba nào khác. Những thông tin cá nhân có thể nhận dạng:
          Chúng tôi thu thập thông tin cá nhân của bạn để đăng ký cho bạn trở
          thành một thành viên chính thức của Cimigo Health. Mỗi thành viên mới
          trong cộng đồng của chúng tôi đều phải điền vào một mẫu đăng ký, trong
          đó yêu cầu các thông tin cá nhân như họ tên, thành phố nơi sinh sống, địa chỉ email, số điện thoai, chuyên khoa và nơi làm
          việc để xác nhận thành viên cho Cimigo Health. 
        </p>
        <p> Hồ sơ cá nhân: Ngoài những thông tin cá nhân có thể nhận dạng, mỗi thành
          viên cần phải trả lời thêm một số câu hỏi để hoàn thiện hồ sơ của
          mình. Thông tin trong hồ sơ gồm các thông tin về trình độ chuyên môn
          và kinh nghiệm làm việc. Hồ sơ thông tin được sử dụng để xếp từng
          thành viên vào từng nhóm mục tiêu (ví dụ, các bác sĩ tai mũi họng nhi
          có 5 năm kinh nghiệm trở lên) và nó cũng cho phép
          chúng tôi xác định trước các loại điều tra khảo sát nào là phù hợp
          nhất để gửi cho bạn. </p>
        <p>
        Các câu hỏi và khảo sát những câu trả lời và kết quả của cuộc khảo sát và
          nghiên cứu sẽ được sử dụng duy nhất cho mục đích nghiên cứu và được
          chia sẻ với khách hàng của chúng tôi, các nhóm nghiên cứu nội bộ và
          các đối tác của chúng tôi và các câu trả lời này luôn được công bố
          dưới dạng một nhóm chứ không phải của từng cá nhân cho từng câu hỏi.
          Kết quả cá nhân không bao giờ được chia sẻ, trao đổi, cho mượn hoặc
          buôn bán cho một bên thứ ba (ví dụ "Bác sĩ sản phụ khoa thường dùng
          thuốc A" thay vì "Bác sĩ X thường
          dùng thuốc A" 
        </p>
        <p>          Các thông tin khác chúng tôi có thể yêu
          cầu người dùng cung cấp: Chúng tôi có thể yêu cầu người sử dụng cung
          cấp thông tin cá nhân cho chúng tôi bất kỳ lúc nào. Chẳng hạn, điều
          này xảy ra trong trường hợp bạn trình báo một vấn đề về một trong những dịch vụ của
          chúng tôi. Bất kỳ thông tin nào được thu thập lại trong những dịp như
          vậy đều hoàn toàn tuân theo chính sách bảo mật của chúng tôi, trừ khi
          nó được quy định lại rõ ràng trong lúc thu thập thông tin. Do đó, điều quan trọng là bạn
          phải đọc kỹ và hiểu được những điều khoản đã thỏa thuận, vì bản chất
          của những đề nghị đó có thể yêu cầu các điều kiện bảo mật khác có thể
          nằm ngoài Chính sách bảo mật này.</p>
        
        <h4>4. Cimigo sẽ sử dụng những thông tin này như thế nào?</h4>
        <p>
          Những cuộc khảo sát luôn ẩn danh: tên, địa chỉ email và số
          điện thoại của bạn không bao giờ được chuyển cho những công
          ty hoặc những người khác. Điều này áp dụng cho những người tham gia
          vào những lần nghiên cứu và khảo sát của chúng tôi và đây là yêu cầu
          pháp lý cho từng cuộc điều tra. Các thông tin mà có thể được dùng để
          truy ra một người cụ thể sẽ không bao giờ được sử dụng cho các mục
          đích phi nghiên cứu như tiếp thị trực tiếp, thiết lập danh sách địa
          chỉ, xếp hạng tín dụng, việc thu tiền hoặc các hoạt động tiếp thị khác
          liên quan đến những đáp viên cá nhân đó. Với việc tôn trọng quyền
          riêng tư của cá nhân, Cimigo sẽ không bao giờ cho, bán, cho thuê, hay
          trao đổi thông tin này cho một bên thứ ba nào khác trong bất kỳ trường
          hợp nào.
        </p>
        <h4>5. Bạn có thể truy cập thông tin cá nhân bằng cách nào?</h4>
        <p>
          Bạn luôn có thể truy cập trang web của chúng tôi và thay đổi hồ sơ cá
          nhân của riêng bạn. Bạn sẽ được yêu cầu cung cấp địa chỉ email và mật
          khẩu của bạn nếu bạn muốn xem, thay đổi hoặc thay thế hồ sơ của bạn.
        </p>
        <h4>6. Bạn có thể ngừng sự tham gia của mình bằng cách nào?</h4>
        <p>
          Bạn có thể chấm dứt việc làm thành viên của bạn bất cứ lúc nào. Để hủy
          đăng ký, bạn phải đăng nhập vào tài khoản của bạn. Bạn sẽ nhận được
          một email để xác nhận việc hủy bỏ tài khoản của bạn vì mục đích an
          ninh.
        </p>
        <h4>
          7. Làm thế nào để chúng tôi thông báo cho bạn biết về những thay đổi
          trong chính sách bảo mật?
        </h4>
        <p>
          Nếu chúng tôi quyết định thay đổi chính sách bảo mật, chúng tôi sẽ
          công bố những thay đổi trên trang chủ của chúng tôi hoặc gửi cho người
          dùng của chúng tôi một email với những thay đổi, để đảm bảo người sử
          dụng luôn nhận thức được những thông tin chúng tôi thu thập, chúng tôi
          sử dụng nó như thế nào, và trong hoàn cảnh nào chúng tôi có thể công
          bố những thông tin này.
        </p>
        <h4>8. Các tổ chức và các quy định mà chúng tôi tuân theo</h4>
        <p>
          Tất cả các nghiên cứu thực hiện trên Internet phải tuân theo các quy
          tắc và sáng kiến hàng đầu của Quy tắc quốc tế về tiếp thị và thực hành
          nghiên cứu xã hội ICC/ESOMAR. Nghiên cứu thị trường phải luôn luôn tôn
          trọng các quyền của các đáp viên và người sử dụng Internet khác. Nó
          phải được thực hiện bằng cách thức chấp nhận được đối với tất cả những
          người liên quan và đối với công chúng, và nó phải tuân thủ các quy
          định của quốc gia và quốc tế. Các nhà nghiên cứu phải tránh những hành
          động có thể làm hỏng danh tiếng của hoặc ảnh hưởng đến sự tin tưởng mà
          mọi người dành cho nghiên cứu trên Internet. Vào trang web:
          www.esomar.org để biết thêm thông tin về ESOMAR và Quy tắc quốc tế về
          tiếp thị và thực hành nghiên cứu xã hội ICC/ESOMAR. 
        </p>

        <p>
        Liên hệ: Nếu bạn
          có thắc mắc thêm về Chính sách bảo mật của chúng tôi, xin vui lòng
          liên hệ với chúng tôi qua <strong>anhdu@cimigo.com</strong>
        </p>
      </div>
    );
  }
}
