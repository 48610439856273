import React from "react";
import { connect } from "react-redux";
import { destroy, isPristine } from "redux-form";
import * as actionAuth from "../../actions/auth-action";
import SnackBar from "../shared/snackBar";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { USERNAME_FORGOT_PASSWORD } from "../../core/system.constants";
import { Redirect } from "react-router-dom";

class ForgotPassword extends React.Component {
  constructor() {
    super();

    this.state = {
      redirect: false,
      snackBar: {
        message: "",
        variant: "",
        open: false
      },
      clipLoader: {
        loading: false
      }
    };

    this.forgotPassword = this.forgotPassword.bind(this);
    this.cancelSnackBar = this.cancelSnackBar.bind(this);
  }

  cancelSnackBar = () => {
    this.setState({
      snackBar: {
        ...this.state.snackBar,
        open: false
      }
    });
  };

  forgotPassword = userData => {
    this.setState({
      clipLoader:{
        loading: true
      }
    });
    localStorage.setItem(USERNAME_FORGOT_PASSWORD, userData.username);
    console.log(userData);
    actionAuth.forgotPassword(userData).then(
      res => {
        //this.props.destroy("ForgotPasswordForm");
        this.setState({
          snackBar: {
            message: `Vui lòng kiểm tra email.`,
            variant: "success",
            open: true
          },
          clipLoader:{
            loading: false
          },
          redirect: true
        });
      },
      error => {
        this.setState({
          snackBar: {
            message: error,
            variant: "error",
            open: true
          },
          clipLoader:{
            loading: false
          }
        });
      }
    );
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/xac-nhan-otp' />
    }
  }

  render() {
    const { message, variant, open } = this.state.snackBar;
    const { loading } = this.state.clipLoader;
    return (
      <div className="text-center mt-10 mb-5">
        <div className="col-md-4 center-block">
          <h3 className="uppercase">quên mật khẩu</h3>
          {this.renderRedirect()}
          <div className="text-left mt-3">
            <ForgotPasswordForm submitCb={this.forgotPassword} indicator={loading}/>
          </div>

          <div className="text-left">
            <SnackBar
              message={message}
              variant={variant}
              open={open}
              handleCloseCb={this.cancelSnackBar}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ChangePasswordForm: state.form.ChangePasswordForm,
    pristine: isPristine("ForgotPasswordForm")(state)
  };
};

export default connect(
  mapStateToProps,
  { destroy }
)(ForgotPassword);
