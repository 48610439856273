import React from 'react'
import UploadImageForm from './UploadImageForm';

export class UploadImage extends React.Component{
    
    render(){
        return (
            <div className='mt-6 text-center col-md-8 offset-md-2'>
                <h1>Tải hình ảnh chứng chỉ hành nghề</h1>
                <UploadImageForm/>
            </div>
        );
    }
}