import React from "react";
import Login  from "../components/login/Login";

export default class LoginPage extends React.Component {
  render() {
    return (
      <section id="login" className="container mt-5">
        
        <Login sourceUrl={(this.props.location.state && this.props.location.state.sourceUrl) ? this.props.location.state.sourceUrl : null }/>
      </section>
    );
  }
}
