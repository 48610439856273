import React, {Component} from 'react';
import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import {Link} from 'react-router-dom';

import TextField from '../ui/TextField';

import facebookLogo from '../../assets/icon/facebook-fill.svg';
import googleLogo from '../../assets/icon/google.svg';
import zaloLogo from '../../assets/icon/zalo.svg';
import './SocialLogin.css';

class SocialLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        mobile_phone: '',
        password: '',
      },
      errors: {},
      is_submitting: false,
      is_FB_ready: false,
      is_Zalo_ready: false,
      is_GG_ready: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener('ZaloObjectReady', this.initZaloLogin);
    document.addEventListener('FBObjectReady', this.initFBLogin);
    document.addEventListener('GGObjectReady', this.initGGLogin);

    window.Zalo.init({
      version: '2.0',
      appId: process.env.REACT_APP_ZALO_API_APP_ID,
      redirectUrl: process.env.REACT_APP_ZALO_API_REDIRECT_URI,
    });

    // Broadcast an event when Zalo object is ready
    var zaloInitEvent = new Event('ZaloObjectReady');
    document.dispatchEvent(zaloInitEvent);

    window.fbAsyncInit = function() {
      if (window.FB) {
        window.FB.init({
          appId: process.env.REACT_APP_FACEBOOK_API_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v3.3',
        });
        var fbInitEvent = new Event('FBObjectReady');
        document.dispatchEvent(fbInitEvent);
      }
    };

    window.fbAsyncInit();
    window.googleAsyncInit = function(){
      if (window.gapi) {
        var googleInitEvent = new Event('GGObjectReady');
        document.dispatchEvent(googleInitEvent);
      }
    }
    window.googleAsyncInit();
  }

  componentWillUnmount() {
    document.removeEventListener('ZaloObjectReady', this.initZaloLogin);
    document.removeEventListener('FBObjectReady', this.initFBLogin);
    document.removeEventListener('GGObjectReady', this.initGGLogin);
  }

  initZaloLogin = () => {
    this.Zalo = window.Zalo;
    this.setState({
      is_Zalo_ready: true,
    });
  };

  initFBLogin = () => {
    this.FB = window.FB;
    this.setState({
      is_FB_ready: true,
    });
  };
  initGGLogin = () => {
    this.gapi = window.gapi;

    window.gapi.load('auth2', () => {
      // Retrieve the singleton for the GoogleAuth library and set up the client.
      this.auth2 = this.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
        cookiepolicy: 'single_host_origin',
        // Request scopes in addition to 'profile' and 'email'
        //scope: 'additional_scope'
      });
      this.attachSignin(document.getElementById('google-login-btn'));
    });
  };

  attachSignin = element => {
    this.auth2.attachClickHandler(
      element,
      {},
      this.onGGLoginSuccess,
      this.onGGLoginFailure,
    );
  };

  onGGLoginSuccess = google_user => {
    let profile = google_user.getBasicProfile();
    let auth_response = google_user.getAuthResponse();
    let info = {
      user: {
        id: profile.getId(),
        /*name: profile.getName(),
                email: profile.getEmail(),
                picture: profile.getImageUrl()*/
      },
      access_token: auth_response.id_token,
      ref_code: this.props.ref_code,
    };

    this.props.onLogin(info, 'google');
  };

  onGGLoginFailure = error => {};

  zaloLogin = () => {
    this.Zalo.getLoginStatus(response => {
      if (response.status === 'connected') {
        //this.setupFacebookUser(response);
        this.Zalo.login(new Date().getTime().toString(), 'get_profile');
        window.location.href =
          'https://oauth.zaloapp.com/v3/auth?app_id=' +
          process.env.REACT_APP_ZALO_API_APP_ID +
          '&redirect_uri=' +
          process.env.REACT_APP_ZALO_API_REDIRECT_URI +
          (this.props.ref_code ? ('&state=' + this.props.ref_code): '');
      } else {
        // Login if not connect
        //this.Zalo.login(new Date().getTime().toString(), 'get_profile');
        window.location.href =
          'https://oauth.zaloapp.com/v3/auth?app_id=' +
          process.env.REACT_APP_ZALO_API_APP_ID +
          '&redirect_uri=' +
          process.env.REACT_APP_ZALO_API_REDIRECT_URI +
          '&state=' +
          this.props.ref_code;
      }
    });
  };

  facebookLogin = response => {
    this.FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        this.onFBLoginSuccess(response);
      } else {
        this.FB.login(this.facebookLoginHandler, {
          scope: 'public_profile, email',
        });
      }
    });
  };

  facebookLoginHandler = response => {
    if (response.status === 'connected') {
      this.onFBLoginSuccess(response);
    }
  };

  onFBLoginSuccess = response => {
    let info = {
      user: {
        id: response.authResponse.userID,
      },
      access_token: response.authResponse.accessToken,
      ref_code: this.props.ref_code,
    };

    this.props.onLogin(info, 'facebook');
  };

  handleChange(e) {
    const {name, value} = e.target;

    this.setState(current_state => {
      return {
        user: {
          ...current_state.user,
          [name]: value,
        },
        errors: {
          ...current_state.errors,
          [name]: [],
        },
      };
    });
  }

  validateInput(data) {
    let errors = {};

    if (!data.mobile_phone || validator.isEmpty(data.mobile_phone)) {
      errors.mobile_phone = ['Mobile phone required'];
    } else if (!validator.isMobilePhone(data.mobile_phone, 'vi-VN')) {
      errors.mobile_phone = ['Mobile phone invalid'];
    }

    if (validator.isEmpty(data.password)) {
      errors.password = ['Password required'];
    }

    return {
      errors,
      is_valid: isEmpty(errors),
    };
  }

  _isValid() {
    const {errors, is_valid} = this.validateInput(this.state.user);

    if (!is_valid) {
      this.setState({errors});
    }

    return is_valid;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this._isValid()) {
      this.setState({errors: {}, is_submitting: true});
      this.props.onLogin(this.state.user);
    }
  }

  render() {
    const {user, errors, is_FB_ready} = this.state;
    const server_error = this.props.errors;

    return (
      <div className="login-wrap">
        <div className="social-login">
          <h3>{this.props.title ? this.props.title : 'Đăng nhập sử dụng mạng xã hội'}</h3>
          <button
            disabled={this.props.is_loading ? 'disabled' : ''}
            onClick={this.zaloLogin}
            className="social-login-btn zalo-login-link">
            <img src={zaloLogo} alt="Login with Zalo" />{' '}
            <span>{this.props.zalo_title ? this.props.zalo_title : 'Đăng nhập bằng Zalo'}</span>
          </button>
          {is_FB_ready && (
            <button
              disabled={this.props.is_loading ? 'disabled' : ''}
              onClick={this.facebookLogin}
              className="social-login-btn facebook-login-link">
              <img src={facebookLogo} alt="Login with Facebook" />{' '}
              <span>{this.props.fb_title ? this.props.fb_title : 'Đăng nhập bằng Facebook'}</span>
            </button>
          )}
          <button
            disabled={this.props.is_loading ? 'disabled' : ''}
            id="google-login-btn"
            className="social-login-btn google-login-link">
            <img src={googleLogo} alt="Login with Gmail" />{' '}
            <span>{this.props.gg_title ? this.props.gg_title : 'Đăng nhập bằng Gmail'}</span>
          </button>
          {false && (
            <div className="login-sep">
              <div className="sep-label">OR</div>
            </div>
          )}
        </div>
        {false && (
          <form className="login-form" onSubmit={this.handleSubmit}>
            {!isEmpty(server_error) ? (
              <div className="summary-errors">
                <span className="msg-item">{server_error}</span>
              </div>
            ) : (
              ''
            )}
            <div className="login-input-group">
              <TextField
                name="mobile_phone"
                label="Mobile phone"
                value={user.mobile_phone}
                onChange={this.handleChange}
                errors={errors.mobile_phone}
              />
              <TextField
                name="password"
                label="Password"
                value={user.password}
                onChange={this.handleChange}
                errors={errors.password}
                type="password"
              />
            </div>
            <div className="extra-actions">
              <Link to="/" className="forgot-password-link">
                Forgot your password?
              </Link>
            </div>
            <div className="form-foot">
              <button
                type="submit"
                className="submit-btn"
                disabled={this.props.is_loading}>
                {this.props.is_loading ? (
                  <span>
                    <i className="fas fa-spinner spin" />
                    Logging in
                  </span>
                ) : (
                  <span>Log in</span>
                )}
              </button>
              <span className="signup-text">
                Don't have account?{' '}
                <Link to="/" className="signup-link">
                  Sign up
                </Link>
              </span>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default SocialLogin;
