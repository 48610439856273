import { connect } from 'react-redux';

import AdditionalInfo from './AdditionalInfo';
import * as authActions from '../../actions/auth-action';

const map_state_to_props = (state) => {
    return {
        ref_code: state.user.data.invitation_code,
        isAuth: state.auth.isAuth,
        user: state.user.data
    }
}

const actions_to_props = {
    socialLogin: authActions.socialLogin
}

export default connect(map_state_to_props, actions_to_props)(AdditionalInfo);

