import React from "react";
import ReactHtmlParser from 'react-html-parser'

export default class FAQ extends React.Component {
    render() {
        return (
          <div className="row">
            <div className="tab-content accordion-maxWidth" id="faq-tab-content">
              <div id="accordion">
                <div className="card">
                  <div className="card-header" id="heading-a2368a53b9bc4565b76d6b7a7b8be6df">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse-a2368a53b9bc4565b76d6b7a7b8be6df"
                        aria-expanded="true"
                        aria-controls="collapse-a2368a53b9bc4565b76d6b7a7b8be6df"
                      >
                        Tôi có phải đóng phí để tham gia?
                      </button>
                    </h5>
                  </div>
    
                  <div
                    id="collapse-a2368a53b9bc4565b76d6b7a7b8be6df"
                    className="collapse show"
                    aria-labelledby="heading-a2368a53b9bc4565b76d6b7a7b8be6df"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {ReactHtmlParser(`<p>
        Chúng tôi chỉ đòi hỏi thời gian và ý kiến của bạn. Bạn không cần phải đóng khoản tiền nào. Chúng tôi cũng không bao giờ yêu cầu bạn cung cấp thông tin tài khoản ngân hàng hay thẻ tín dụng. Đăng ký thành viên là miễn phí.
    </p>`)}
                    </div>
                  </div>
                </div>
            
                <div className="card">
                  <div className="card-header" id="heading-b5f96f0b6e224c8f8b728ecfeec043fe">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse-b5f96f0b6e224c8f8b728ecfeec043fe"
                        aria-expanded="false"
                        aria-controls="collapse-b5f96f0b6e224c8f8b728ecfeec043fe"
                      >
                       Khi là thành viên, tôi sẽ làm những gì?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse-b5f96f0b6e224c8f8b728ecfeec043fe"
                    className="collapse"
                    aria-labelledby="heading-b5f96f0b6e224c8f8b728ecfeec043fe"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                     {ReactHtmlParser(` <p>
        Là thành viên, bạn sẽ được mời tham gia những cuộc phỏng vấn hoặc khảo sát trực tiếp hoặc trực tuyến và cho ý kiến về những sản phẩm, dịch vụ hoặc các mẩu quảng cáo sử dụng trong ngành y tế. Sự tham gia của bạn rất quan trọng và sẽ giúp cải thiện chất lượng của chúng.
    </p>`)}
                    </div>
                  </div>
                </div>
               
                <div className="card">
                  <div className="card-header" id="heading-9d1092cf7b8a4b438cda1fe658b55bbf">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse-9d1092cf7b8a4b438cda1fe658b55bbf"
                        aria-expanded="false"
                        aria-controls="collapse-9d1092cf7b8a4b438cda1fe658b55bbf"
                      >
                       Tôi sẽ nhận được lời mời khảo sát bao lâu một lần?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse-9d1092cf7b8a4b438cda1fe658b55bbf"
                    className="collapse"
                    aria-labelledby="heading-9d1092cf7b8a4b438cda1fe658b55bbf"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {ReactHtmlParser(` <p>
        Mức thù lao và cách thức nhận thù lao khi hoàn thành một bài phỏng vấn hay khảo sát sẽ được Cimigo Health quy định trong nội dung thư mời. Bạn có thể theo dõi các khoản thù lao của mình trong mục Hồ sơ cá nhân.
    </p>`)}
                    </div>
                  </div>
                </div>
              
                <div className="card">
                  <div className="card-header" id="heading-af3d530b265142b2838ec3de7b2f6cd5">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse-af3d530b265142b2838ec3de7b2f6cd5"
                        aria-expanded="false"
                        aria-controls="collapse-af3d530b265142b2838ec3de7b2f6cd5"
                      >
                      Tôi sẽ nhận được thù lao như thế nào?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse-af3d530b265142b2838ec3de7b2f6cd5"
                    className="collapse"
                    aria-labelledby="heading-af3d530b265142b2838ec3de7b2f6cd5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {ReactHtmlParser(` <p>
        Mức thù lao và cách thức nhận thù lao khi hoàn thành một bài phỏng vấn hay khảo sát sẽ được Cimigo Health quy định trong nội dung thư mời. Bạn có thể theo dõi các khoản thù lao của mình trong mục Hồ sơ cá nhân.
    </p>`)}
                    </div>
                  </div>
                </div>
              
                <div className="card">
                  <div className="card-header" id="heading-8217d55d17814584935200443b9a8539">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse-8217d55d17814584935200443b9a8539"
                        aria-expanded="false"
                        aria-controls="collapse-8217d55d17814584935200443b9a8539"
                      >
                     Thông tin cá nhân của tôi được bảo mật ra sao?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse-8217d55d17814584935200443b9a8539"
                    className="collapse"
                    aria-labelledby="heading-8217d55d17814584935200443b9a8539"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {ReactHtmlParser(` <p>
        Thông tin cá nhân và những câu trả lời của bạn sẽ được giữ bí mật. Dữ liệu từ các cuộc điều tra của chúng tôi sẽ luôn được báo cáo dưới dạng các câu trả lời tổng hợp, chứ không phải của riêng từng cá nhân. Bạn sẽ chỉ nhận được lời mời làm khảo sát từ Cimigo Health qua email hoặc điện thoại. Không một bên thứ ba nào có thể biết được địa chỉ email hoặc số điện thoại của bạn. Tìm hiểu thêm về việc bảo vệ dữ liệu trong chính sách bảo mật của chúng tôi.
    </p>`)}
                    </div>
                  </div>
                </div>
              
               <div className="card">
                  <div className="card-header" id="heading-197ff9bbd4e24a64a2194eed0590853c">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse-197ff9bbd4e24a64a2194eed0590853c"
                        aria-expanded="false"
                        aria-controls="collapse-197ff9bbd4e24a64a2194eed0590853c"
                      >
                     Làm thế nào tôi có thể hủy đăng ký?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse-197ff9bbd4e24a64a2194eed0590853c"
                    className="collapse"
                    aria-labelledby="heading-197ff9bbd4e24a64a2194eed0590853c"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {ReactHtmlParser(` <p>
        Chúng tôi hi vọng bạn có những trải nghiệm thú vị khi là thành viên của Cimigo Health, nhưng nếu bạn không muốn tham gia nữa, điều này cũng rất dễ dàng. Chỉ cần đăng nhập vào tài khoản của bạn và chọn nút "Hủy đăng ký ".
    </p>`)}
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
    
            <div className="spacer-60" />
          </div>
        );
      }
}




// const questions = [
//     {
//         idCate: "tab1",
//         icon: "fas fa-question-circle", 
//         name: "Frequently Asked Questions",
//         questions: [
//             { id : 1, question : "Just once I'd like to eat dinner with a celebrity?", answer : ` <p>
//                         Yes, if you make it look like an electrical fire. When
//                         you do things right, people won't be sure you've done
//                         anything at all. I was having the most wonderful dream.
//                         Except you were there, and you were there, and you were
//                         there! No argument here. Goodbye, cruel world. Goodbye,
//                         cruel lamp. Goodbye, cruel velvet drapes, lined with
//                         what would appear to be some sort of cruel muslin and
//                         the cute little pom-pom curtain pull cords. Cruel though
//                         they may be.
//                     </p>
//                     <p>
//                         <strong>Example: </strong>Shut up and get to the point!
//                     </p>`
//             },
//             { id : 2, question : "Bender, I didn't know you liked cooking?", answer : `  <p>
//                     That's so cute. Can we have Bender Burgers again? Is the
//                     Space Pope reptilian!? I wish! It's a nickel. Bender!
//                     Ship! Stop bickering or I'm going to come back there and
//                     change your opinions manually!
//                     </p>
//                     <p>
//                     <strong>Example: </strong>Okay, I like a challenge. Is
//                     that a cooking show? No argument here.
//                     </p>`
//             },
//             { id : 3, question : "My fellow Earthicans?", answer : `<p>
//                     As I have explained in my book 'Earth in the Balance',
//                     and the much more popular 'Harry Potter and the Balance
//                     of Earth', we need to defend our planet against
//                     pollution. Also dark wizards. Fry, you can't just sit
//                     here in the dark listening to classNameical music.
//                     </p>
//                     <p>
//                     <strong>Example: </strong>Actually, that's still true.
//                     Well, let's just dump it in the sewer and say we
//                     delivered it.
//                     </p>`
//             },
//             { id : 4, question : " Who am I making this out to?", answer : `<p>
//                     Morbo can't understand his teleprompter because he
//                     forgot how you say that letter that's shaped like a man
//                     wearing a hat. Also Zoidberg. Can we have Bender Burgers
//                     again? Goodbye, cruel world. Goodbye, cruel lamp.
//                     Goodbye, cruel velvet drapes, lined with what would
//                     appear to be some sort of cruel muslin and the cute
//                     little pom-pom curtain pull cords.
//                     </p>
//                     <p>
//                     <strong>Example: </strong>Cruel though they may be...
//                     </p>`
//             },
//         ]
    
//     },
//     { idCate: "tab2", icon: "fas fa-user-alt", name: "Profile", questions: [
//         { id : 5, question : "Does anybody else feel jealous and aroused and worried?", answer : ` <p>
//                 Kif, I have mated with a woman. Inform the men. This is
//                 the worst part. The calm before the battle. Bender,
//                 being God isn't easy. If you do too much, people get
//                 dependent on you, and if you do nothing, they lose hope.
//                 You have to use a light touch. Like a safecracker, or a
//                 pickpocket.
//             </p>
//             <p>
//                 <strong>Example: </strong>There's no part of that
//                 sentence I didn't like! You, a bobsleder!? That I'd like
//                 to see!
//             </p>`
//         },
//         { id : 6, question : "This opera's as lousy as it is brilliant?", answer : `<p>
//                         Your lyrics lack subtlety. You can't just have your
//                         characters announce how they feel. That makes me feel
//                         angry! It's okay, Bender. I like cooking too.
//                         Interesting. No, wait, the other thing: tedious.
//                     </p>
//                     <p>
//                         <strong>Example: </strong>Of all the friends I've had…
//                         you're the first. But I know you in the future. I
//                         cleaned your poop. Then we'll go with that data file!
//                     </p>`
//         },
//         { id : 7, question : "Who are you, my warranty?!", answer : `<p>
//                         Oh, I think we should just stay friends. I'll tell them
//                     you went down prying the wedding ring off his cold, dead
//                     finger. Aww, it's true. I've been hiding it for so long.
//                     Say it in Russian! Then throw her in the laundry room,
//                     which will hereafter be referred to as "the brig".
//                 </p>
//                 <p>
//                     <strong>Example: </strong> We're rescuing ya. Robot 1-X,
//                     save my friends! And Zoidberg!{" "}
//                     <em>Then we'll go with that data file!</em> Okay, I like
//                     a challenge.
//                 </p> `
//         },
//         { id : 8, question : "I haven't felt much of anything since my guinea pig died?", answer : `<p>
//                     And I'm his friend Jesus. Oh right. I forgot about the
//                     battle. OK, if everyone's finished being stupid. We'll
//                     need to have a look inside you with this camera. I'm
//                     just glad my fat, ugly mama isn't alive to see this day.
//                 </p>
//                 <p>
//                     <strong>Example: </strong> Isn't it true that you have
//                     been paid for your testimony? Quite possible.
//                 </p>`
//         },
//     ]
//  },
//     { idCate: "tab3", icon: "fas fa-user-circle", name: "Account", questions: [
//         { id : 9, question : "Say what?", answer : ` <p>
//                 Look, last night was a mistake. We'll need to have a
//                 look inside you with this camera. Good news, everyone!
//                 There's a report on TV with some very bad news! You
//                 know, I was God once. You lived before you met me?!
//             </p>
//             <p>
//                 <strong>Example: </strong>I'm Santa Claus! Pansy. That's
//                 a popular name today. Little "e", big "B"?
//             </p>`
//         },
//         { id : 10, question : "Who's brave enough to fly into something we all keep calling a death sphere?", answer : `<p>
//                 Did I miss something fun? Humans dating robots is sick.
//                 You people wonder why I'm still single? It's 'cause all
//                 the fine robot sisters are dating humans! Kids don't
//                 turn rotten just from watching TV.
//             </p>
//             <p>
//                 <strong>Example: </strong>I usually try to keep my
//                 sadness pent up inside where it can fester quietly as a
//                 mental illness.
//             </p>`
//         },
//         { id : 11, question : "You mean while I'm sleeping in it?", answer : ` <p>
//                 OK, this has gotta stop. I'm going to remind Fry of his
//                 humanity the way only a woman can. You seem
//                 malnourished. Are you suffering from intestinal
//                 parasites? Check it out, y'all. Everyone who was invited
//                 is here. I am Singing Wind, Chief of the Martians.
//             </p>
//             <p>
//                 <strong>Example: </strong>Man, I'm sore all over. I feel
//                 like I just went ten rounds with mighty Thor.
//             </p>`
//         },
//         { id : 12, question : "And until then, I can never die?", answer : `<p>
//                 I don't want to be rescued. I videotape every customer
//                 that comes in here, so that I may blackmail them later.
//                 Ah, computer dating. It's like pimping, but you rarely
//                 have to use the phrase "upside your head."
//             </p>
//             <p>
//                 <strong>Example: </strong>Tell them I hate them.
//             </p>`
//         },
//     ]
//  },
//     { idCate: "tab4", icon: "fas fa-heart", name: "Favorites", questions: [
//         { id : 13, question : "Doomsday device?", answer : ` <p>
//                     Yes, if you make it look like an electrical fire. When
//                     you do things right, people won't be sure you've done
//                     anything at all. I was having the most wonderful dream.
//                     Except you were there, and you were there, and you were
//                     there! No argument here. Goodbye, cruel world. Goodbye,
//                     cruel lamp. Goodbye, cruel velvet drapes, lined with
//                     what would appear to be some sort of cruel muslin and
//                     the cute little pom-pom curtain pull cords. Cruel though
//                     they may be.
//                 </p>
//                 <p>
//                     <strong>Example: </strong>Shut up and get to the point!
//                 </p>`
//         },
//         { id : 14, question : "And so we say goodbye to our beloved pet, Nibbler?", answer : `  <p>
//                 That's so cute. Can we have Bender Burgers again? Is the
//                 Space Pope reptilian!? I wish! It's a nickel. Bender!
//                 Ship! Stop bickering or I'm going to come back there and
//                 change your opinions manually!
//                 </p>
//                 <p>
//                 <strong>Example: </strong>Okay, I like a challenge. Is
//                 that a cooking show? No argument here.
//                 </p>`
//         },
//         { id : 15, question : "Tell her you just want to talk?", answer : `<p>
//                 As I have explained in my book 'Earth in the Balance',
//                 and the much more popular 'Harry Potter and the Balance
//                 of Earth', we need to defend our planet against
//                 pollution. Also dark wizards. Fry, you can't just sit
//                 here in the dark listening to classNameical music.
//                 </p>
//                 <p>
//                 <strong>Example: </strong>Actually, that's still true.
//                 Well, let's just dump it in the sewer and say we
//                 delivered it.
//                 </p>`
//         },
//         { id : 16, question : "Good man. Nixon's pro-war and pro-family?", answer : `<p>
//                 Morbo can't understand his teleprompter because he
//                 forgot how you say that letter that's shaped like a man
//                 wearing a hat. Also Zoidberg. Can we have Bender Burgers
//                 again? Goodbye, cruel world. Goodbye, cruel lamp.
//                 Goodbye, cruel velvet drapes, lined with what would
//                 appear to be some sort of cruel muslin and the cute
//                 little pom-pom curtain pull cords.
//                 </p>
//                 <p>
//                 <strong>Example: </strong>Cruel though they may be...
//                 </p>`
//         },
//     ]
//  },
//     { idCate: "tab5", icon: "fa fa-usd", name: "Transactions", questions: [
//         { id : 17, question : "Take me to your leader?", answer : ` <p>
//                     Yes, if you make it look like an electrical fire. When
//                     you do things right, people won't be sure you've done
//                     anything at all. I was having the most wonderful dream.
//                     Except you were there, and you were there, and you were
//                     there! No argument here. Goodbye, cruel world. Goodbye,
//                     cruel lamp. Goodbye, cruel velvet drapes, lined with
//                     what would appear to be some sort of cruel muslin and
//                     the cute little pom-pom curtain pull cords. Cruel though
//                     they may be.
//                 </p>
//                 <p>
//                     <strong>Example: </strong>Shut up and get to the point!
//                 </p>`
//         },
//         { id : 18, question : "Belligerent and numerous?", answer : `  <p>
//                 That's so cute. Can we have Bender Burgers again? Is the
//                 Space Pope reptilian!? I wish! It's a nickel. Bender!
//                 Ship! Stop bickering or I'm going to come back there and
//                 change your opinions manually!
//                 </p>
//                 <p>
//                 <strong>Example: </strong>Okay, I like a challenge. Is
//                 that a cooking show? No argument here.
//                 </p>`
//         },
//         { id : 19, question : "My fellow Earthicans?", answer : `<p>
//                 As I have explained in my book 'Earth in the Balance',
//                 and the much more popular 'Harry Potter and the Balance
//                 of Earth', we need to defend our planet against
//                 pollution. Also dark wizards. Fry, you can't just sit
//                 here in the dark listening to classNameical music.
//                 </p>
//                 <p>
//                 <strong>Example: </strong>Actually, that's still true.
//                 Well, let's just dump it in the sewer and say we
//                 delivered it.
//                 </p>`
//         },
//         { id : 20, question : " Who am I making this out to?", answer : `<p>
//                 Morbo can't understand his teleprompter because he
//                 forgot how you say that letter that's shaped like a man
//                 wearing a hat. Also Zoidberg. Can we have Bender Burgers
//                 again? Goodbye, cruel world. Goodbye, cruel lamp.
//                 Goodbye, cruel velvet drapes, lined with what would
//                 appear to be some sort of cruel muslin and the cute
//                 little pom-pom curtain pull cords.
//                 </p>
//                 <p>
//                 <strong>Example: </strong>Cruel though they may be...
//                 </p>`
//         },
//     ]
//  },
//     { idCate: "tab6", icon: "fas fa-question", name: "General help", questions: [
//         { id : 21, question : "No, I'm Santa Claus?", answer : ` <p>
//                     Yes, if you make it look like an electrical fire. When
//                     you do things right, people won't be sure you've done
//                     anything at all. I was having the most wonderful dream.
//                     Except you were there, and you were there, and you were
//                     there! No argument here. Goodbye, cruel world. Goodbye,
//                     cruel lamp. Goodbye, cruel velvet drapes, lined with
//                     what would appear to be some sort of cruel muslin and
//                     the cute little pom-pom curtain pull cords. Cruel though
//                     they may be.
//                 </p>
//                 <p>
//                     <strong>Example: </strong>Shut up and get to the point!
//                 </p>`
//         },
//         { id : 22, question : "You, minion. Lift my arm?", answer : `  <p>
//                 That's so cute. Can we have Bender Burgers again? Is the
//                 Space Pope reptilian!? I wish! It's a nickel. Bender!
//                 Ship! Stop bickering or I'm going to come back there and
//                 change your opinions manually!
//                 </p>
//                 <p>
//                 <strong>Example: </strong>Okay, I like a challenge. Is
//                 that a cooking show? No argument here.
//                 </p>`
//         },
//         { id : 23, question : "You are the last hope of the universe?", answer : `<p>
//                 As I have explained in my book 'Earth in the Balance',
//                 and the much more popular 'Harry Potter and the Balance
//                 of Earth', we need to defend our planet against
//                 pollution. Also dark wizards. Fry, you can't just sit
//                 here in the dark listening to classNameical music.
//                 </p>
//                 <p>
//                 <strong>Example: </strong>Actually, that's still true.
//                 Well, let's just dump it in the sewer and say we
//                 delivered it.
//                 </p>`
//         },
//         { id : 24, question : " Who am I making this out to?", answer : `<p>
//                 Morbo can't understand his teleprompter because he
//                 forgot how you say that letter that's shaped like a man
//                 wearing a hat. Also Zoidberg. Can we have Bender Burgers
//                 again? Goodbye, cruel world. Goodbye, cruel lamp.
//                 Goodbye, cruel velvet drapes, lined with what would
//                 appear to be some sort of cruel muslin and the cute
//                 little pom-pom curtain pull cords.
//                 </p>
//                 <p>
//                 <strong>Example: </strong>Cruel though they may be...
//                 </p>`
//         },
//     ]
//  }
// ]


// const faqs = [
//     {
//       _id: "a2368a53b9bc4565b76d6b7a7b8be6df",
//       question: "Tôi có phải đóng phí để tham gia?",
//       answer: ` <p>
//       Chúng tôi chỉ đòi hỏi thời gian và ý kiến của bạn. Bạn không cần phải đóng khoản tiền nào. Chúng tôi cũng không bao giờ yêu cầu bạn cung cấp thông tin tài khoản ngân hàng hay thẻ tín dụng. Đăng ký thành viên là miễn phí.
//   </p>`
//     },
//     {
//       _id: "b5f96f0b6e224c8f8b728ecfeec043fe",
//       question: "Khi là thành viên, tôi sẽ làm những gì?",
//       answer: ` <p>
//       Là thành viên, bạn sẽ được mời tham gia những cuộc phỏng vấn hoặc khảo sát trực tiếp hoặc trực tuyến và cho ý kiến về những sản phẩm, dịch vụ hoặc các mẩu quảng cáo sử dụng trong ngành y tế. Sự tham gia của bạn rất quan trọng và sẽ giúp cải thiện chất lượng của chúng.
//   </p>`
//     },
//     {
//       _id: "9d1092cf7b8a4b438cda1fe658b55bbf",
//       question: "Tôi sẽ nhận được lời mời khảo sát bao lâu một lần?",
//       answer: ` <p>
//       Chúng tôi sẽ gửi cho bạn không quá một bài khảo sát mỗi tháng. Tùy trường hợp bạn có thể cần từ 10 đến 45 phút để hoàn thành bài phỏng vấn, khảo sát.
//   </p>`
//     },
//     {
//       _id: "af3d530b265142b2838ec3de7b2f6cd5",
//       question: "Tôi sẽ nhận được thù lao như thế nào?",
//       answer: ` <p>
//       Mức thù lao và cách thức nhận thù lao khi hoàn thành một bài phỏng vấn hay khảo sát sẽ được Cimigo Health quy định trong nội dung thư mời. Bạn có thể theo dõi các khoản thù lao của mình trong mục Hồ sơ cá nhân.
//   </p>`
//     },
//     {
//       _id: "8217d55d17814584935200443b9a8539",
//       question: "Thông tin cá nhân của tôi được bảo mật ra sao?",
//       answer: ` <p>
//       Thông tin cá nhân và những câu trả lời của bạn sẽ được giữ bí mật. Dữ liệu từ các cuộc điều tra của chúng tôi sẽ luôn được báo cáo dưới dạng các câu trả lời tổng hợp, chứ không phải của riêng từng cá nhân. Bạn sẽ chỉ nhận được lời mời làm khảo sát từ Cimigo Health qua email hoặc điện thoại. Không một bên thứ ba nào có thể biết được địa chỉ email hoặc số điện thoại của bạn. Tìm hiểu thêm về việc bảo vệ dữ liệu trong chính sách bảo mật của chúng tôi.
//   </p>`
//     },
//     {
//       _id: "197ff9bbd4e24a64a2194eed0590853c",
//       question: "Làm thế nào tôi có thể hủy đăng ký?",
//       answer: ` <p>
//       Chúng tôi hi vọng bạn có những trải nghiệm thú vị khi là thành viên của Cimigo Health, nhưng nếu bạn không muốn tham gia nữa, điều này cũng rất dễ dàng. Chỉ cần đăng nhập vào tài khoản của bạn và chọn nút "Hủy đăng ký ".
//   </p>`
//     }
//   ];
// export default class FAQ extends React.Component {
//   renderQuestionCategories = () => {
//     return questions.map((category, index) => {
//       return (
//         <a
//           href={`#${category.idCate}`}
//           className={index === 0 ? "nav-link active" : "nav-link"}
//           data-toggle="pill"
//           role="tab"
//           aria-controls={`${category.idCate}`}
//           aria-selected={index === 0 ? true : false}
//         >
//           <i className={category.icon} /> {category.name}
//         </a>
//       );
//     });
//   };

//   renderQuestions = () => {
//       return questions.map((d, index) => {
//           return (
//             <div className={index === 0 ? "tab-pane show active" : "tab-pane"} id={d.idCate} role="tabpanel" aria-labelledby={d.idCate}>
//               <div className="accordion" id={`accordion-${d.idCate}`}>
//                 {
//                     d.questions.map((ques, i) => {
//                         return (
//                             <div className="card">
//                             <div className="card-header" id={`accordion-${d.idCate}-heading-${i + 1}`}>
//                               <h5>
//                                 <button className="btn btn-link" type="button" 
//                                         data-toggle="collapse" 
//                                         data-target={`#accordion-${d.idCate}-content-${i + 1}`} 
//                                         aria-expanded="false" 
//                                         aria-controls={`accordion-${d.idCate}-content-${i + 1}`}>
//                                   {ques.question}
//                                 </button>
//                               </h5>
//                             </div>

//                             <div className={i === 0 ? "collapse show" : "collapse"} id={`accordion-${d.idCate}-content-${i + 1}`} 
//                                     aria-labelledby={`accordion-${d.idCate}-heading-${i + 1}`} 
//                                     data-parent={`#accordion-${d.idCate}`}>
//                               <div className="card-body">
//                                 { ReactHtmlParser(ques.answer) }
//                               </div>
//                             </div>
//                           </div>
//                         );
//                     })
//                 }
//               </div>
//             </div>
           
           
//           );
//       })
//   }

//   render() {
//     return (
//       <div className="row">
//         <div className="col-lg-12 text-center">
//           <h3>FREQUENTLY ASKED QUESTIONS</h3>
//         </div>
//         <div className="spacer-60" />

//         <div className="col-lg-4">
//           <div className="nav nav-pills faq-nav" id="faq-tabs" role="tablist" aria-orientation="vertical">
//             {this.renderQuestionCategories()}
//           </div>
//         </div>

//         <div className="col-lg-8">
//           <div className="tab-content" id="faq-tab-content">
//             {this.renderQuestions()}
//           </div>
//         </div>
//         <div className="spacer-60" />
//       </div>
//     );
//   }
//}
