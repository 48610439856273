import axios from 'axios';
import authService from './auth-service';
import { BASE_API, API_VERSION } from '../core/system.constants';

class AxiosService{
    axiosInstance = {};
    axiosBaseInstance = {};

    constructor(){
        this.initInstance();
        this.initBaseInstance();
    }

    initBaseInstance(){
        this.axiosBaseInstance = axios.create({
            baseURL: BASE_API+API_VERSION,
            timeout: 5000
        });

        return this.axiosBaseInstance;
    }

    initInstance(){
        this.axiosInstance = axios.create({
            baseURL: BASE_API+API_VERSION,
            timeout: 5000
        });

        this.axiosInstance.interceptors.request.use(
            (config) =>{
                const token = authService.getToken();

                if(token){
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            });
        return this.axiosInstance;
    }

    getInstance(){
        return this.axiosInstance || this.initInstance();
    }

    getBaseInstance(){
        return this.axiosBaseInstance || this.initBaseInstance();
    }
}

export default new AxiosService();
