import React from "react";

import iconProfile from "../../assets/icon/icon-profile.png";
import iconPhone from "../../assets/icon/icon-phone.png";
import iconReward from "../../assets/icon/icon-reward.png";
import iconSurvey from "../../assets/icon/icon-survey.png";

export function Steps() {
  return (
    <div className="wrapper">
      <div className="steps">
        <div className="step">
          <img src={iconProfile} alt="Đăng ký tài khoản" />
          <h2>
            <span>1</span>
            Đăng ký tài khoản
          </h2>
        </div>
        <div className="step">
          <img src={iconPhone} alt="Đăng ký tài khoản" />
          <h2>
            <span>2</span>
            Nhận lời mời tham gia khảo sát
          </h2>
        </div>
        <div className="step">
          <img src={iconSurvey} alt="Đăng ký tài khoản" />
          <h2>
            <span>3</span>
            Hoàn thành khảo sát
          </h2>
        </div>
        <div className="step">
          <img src={iconReward} alt="Đăng ký tài khoản" />
          <h2>
            <span>4</span>
            Nhận thù lao
          </h2>
        </div>
      </div>
    </div>
  );
}
