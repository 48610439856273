import React from "react";
import RegisterSection from "../components/register/RegisterSection";
import { Redirect } from "react-router-dom";
export default class RegisterPage extends React.Component{

    constructor(){
        super();
        this.state = {
            ref_code: undefined
        }
    }

    componentDidMount(){
        const params = new URLSearchParams(this.props.location.search);
        const ref_code = params.get('ref_code');
        this.setState({
            ref_code : ref_code
        });
    }
    render(){
        const { ref_code } = this.state;
        if(ref_code === null)
            return <Redirect to={{ pathname: "/" }} />;
        return(
            <section id="register-section" className="container mt-10">
            <RegisterSection ref_code={ref_code}></RegisterSection>
            </section>
        );
    }
}