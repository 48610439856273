import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch  } from "react-router-dom";
import * as actionAuths from "./actions/auth-action";
import "./App.scss";
import { LoggedInRoute } from "./components/shared/auth/LoggedInRoute";
import ProtectedRoute from "./components/shared/auth/ProtectedRoute";
import Footer from "./components/shared/Footer";
import Header from "./components/shared/Header";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import DashboardPage from "./pages/DashboardPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import ResetPasswordPage from "./pages/ResetPassword";
import UploadImagePage from "./pages/UploadImagePage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import ContactPage from "./pages/ContactPage";
import FAQPage from "./pages/FAQPage";
import LockUserPage from "./pages/LockUserPage";
import RedirectSurveyGizmoPage from "./pages/RedirectSurveyGizmoPage";
import SurveyPage from "./pages/SurveyPage";
import ConfirmOTPPage from "./pages/ConfirmOTPPage";
import RegisterPage from "./pages/RegisterPage";
import RegisterCodePage from "./pages/RegisterCodePage";
import AuthPage from "./pages/AuthPage";
import AdditionalInfoPage from "./pages/AdditionalInfoPage";

const store = require("./reducers").init();

class App extends Component {

  componentWillMount() {
    this.checkAuthState();
  }

  checkAuthState() {
    store.dispatch(actionAuths.checkAuthState());
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <Header />
            <main className="main">
            <Switch>
              <Route exact path="/" component={HomePage} />
              <LoggedInRoute exact path="/dang-nhap" component={LoginPage} />
              <ProtectedRoute exact path="/bang-dieu-khien" component={DashboardPage} />
              <ProtectedRoute exact path="/doi-mat-khau" component={ChangePasswordPage}/>
              <ProtectedRoute exact path="/dang-hinh-anh" component={UploadImagePage}/>
              <ProtectedRoute exact path="/ho-so-ca-nhan" component={ProfilePage} />
              <ProtectedRoute exact path="/redirect/:externalId" component={RedirectSurveyGizmoPage}/>
              <ProtectedRoute exact path="/survey/:status/:sid/:uid" component={SurveyPage}/>
              <Route exact path="/dang-ky" component={RegisterCodePage}/>
              <Route exact path="/dang-ky-thong-tin" component={RegisterPage}/>
              <Route exact path="/quen-mat-khau" component={ForgotPasswordPage}/>
              <Route exact path="/xac-nhan-otp" component={ConfirmOTPPage}/>
              <Route exact path="/reset-password/:userID/:key" component={ResetPasswordPage}/>
              <Route exact path="/chinh-sach-bao-mat" component={PrivacyPage}/>
              <Route exact path="/thong-tin-dieu-khoan" component={TermsPage}/>
              <Route exact path="/lien-he" component={ContactPage}/>
              <Route exact path="/hoi-dap" component={FAQPage}/>
              <Route path="/auth/zalo" component={AuthPage}/>
              <Route exact path="/bo-sung-thong-tin" component={AdditionalInfoPage}/>
              <ProtectedRoute exact path="/lock/:token" component={LockUserPage}/>
            </Switch>
          </main>
            
            <Footer />
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
