import React from "react";
import { Field, reduxForm } from "redux-form";
import { DPInput } from "../shared/form/DPInput";

const ChangePasswordForm = props => {
  const { handleSubmit, submitCb } = props;
  return (
    <form onSubmit={handleSubmit(submitCb)}>
      <Field
        name="password"
        type="password"
        label="Mật khẩu hiện tại"
        className="material-ui-form"
        component={DPInput}
      />
      <Field
        name="newPassword"
        type="password"
        label="Mật khẩu mới"
        className="material-ui-form"
        component={DPInput}
      />
      <Field
        name="newPasswordConfirmation"
        type="password"
        label="Nhập lại mật khẩu mới"
        className="material-ui-form"
        component={DPInput}
      />
      <button
        className="action-btn blue register-btn btn-block mt-4"
        type="submit"
      >
        Xác nhận
      </button>
    </form>
  );
};

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = "Vui lòng nhập mật khẩu hiện tại";
  }

  if (!values.newPassword) {
    errors.newPassword = "Vui lòng nhập mật khẩu mới";
  }

  if (values.newPassword !== values.newPasswordConfirmation) {
    errors.newPasswordConfirmation = "Mật khẩu xác nhận chưa chính xác";
  }

  return errors;
};

export default reduxForm({
  form: "ChangePasswordForm",
  validate
})(ChangePasswordForm);
