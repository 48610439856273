import React from "react";
import TextField from '@material-ui/core/TextField'

export const DPInput = ({
  label,
  input,
  type,
  className,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
      label={label}
      placeholder={label}
      type={type}
      error={touched && invalid}
      helperText={touched && error}
      className={className}
      {...input}
      {...custom}
    />
);

export const DPInputSimple = ({
  input,
  label,
  type,
  symbol,
  className,
  placeholder,
  meta: { touched, error, warning }
}) => (
  <div className="form-group mb-3">
    <label>{label}</label>
    <div className="input-group">
      {symbol && 
        <div className="input-group-prepend">
          <div className="input-group-text">{symbol}</div>
        </div>
      }
       <input {...input} type={type} className={className} placeholder={placeholder} />
    </div>
    {touched &&
        // ((error && <div className='alert alert-danger mt-2 dp-alert-padding'>{error}</div>))
        ((error && <span className="dp-validate">{error}</span>))
    }
  </div>
);


