import React from "react";
import ForgotPassword from "../components/forgot-password/ForgotPassword";

export default class ForgotPasswordPage extends React.Component {
  render() {
    return (
      <section id="forgotPassword" className="container mt-5">
        <ForgotPassword />
      </section>
    );
  }
}
