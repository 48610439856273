import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Content} from './Content';
import {Steps} from './Steps';
import {connect} from 'react-redux';

import * as actionCity from '../../actions/city-action';
import * as actionSpecialist from '../../actions/specialist-action';
import * as actionDocor from '../../actions/doctor-action';

class HomeMainSection extends Component {
  constructor(props) {
    super(props);
    this.joinSectionRef = React.createRef();
    this.scrollToJoin = this.scrollToJoin.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(actionCity.fetchCities());
    this.props.dispatch(actionSpecialist.fetchSpecialists());
    this.props.dispatch(actionDocor.fetchDoctorTitles());
    this.props.dispatch(actionDocor.fetchDoctocAcademics());
  }

  scrollToJoin() {
    window.scrollTo({
      top: this.joinSectionRef.current.offsetTop,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <React.Fragment>
        <section>
          <div className="image-intro" />
          <div className="intro-content ">
            <div className="wrapper">
              <div className="wrapper-content wrapper-content-right">
                <h1 className="wrapper-content-margin-left">
                  Chúng tôi lắng nghe và trân trọng ý kiến của chuyên gia y tế
                </h1>
                <div className="action-group">
                  {this.props.isAuth ? (
                    <Link
                      className="action-btn orange join-link"
                      to="/bang-dieu-khien">
                      Khảo sát của bạn
                    </Link>
                  ) : (
                    <Link className="action-btn orange join-link" to="/dang-ky">
                      Đăng ký thành viên
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="how">
          <Steps />
        </section>
        <section className="join" ref={this.joinSectionRef}>
          <div className="wrapper">
            <div className="description-block block">
              <Content />
            </div>
            {/* <div className="request-block block">
              <Register
                cities={this.props.cities}
                specialists={this.props.specialists}
                doctorTitles={this.props.doctorTitles}
                doctorAcademics={this.props.doctorAcademics}
              />
            </div> */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cities: state.cities.data,
    specialists: state.specialists.data,
    doctorTitles: state.doctorTitles.data,
    doctorAcademics: state.doctorAcademics.data,
    isAuth: state.auth.isAuth
  };
}

export default connect(mapStateToProps)(HomeMainSection);
