export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';  

export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';

export const FETCH_SPECIALIST_SUCCESS = 'FETCH_SPECIALIST_SUCCESS';

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'; 
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'; 

export const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS';
export const UPDATE_SURVEY_DENY = 'UPDATE_SURVEY_DENY'; 

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const UPDATE_DOCTOR_PROFILE_SUCCESS = 'UPDATE_DOCTOR_PROFILE_SUCCESS';
export const UPDATE_DOCTOR_PROFILE_FAIL = 'UPDATE_DOCTOR_PROFILE_FAIL';

export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAIL = 'FETCH_PROFILE_FAIL';

export const FETCH_PROFILE_NOTCOMPTELE_SUCCESS = 'FETCH_PROFILE_NOTCOMPTELE_SUCCESS';
export const FETCH_PROFILE_NOTCOMPTELE_FAIL = 'FETCH_PROFILE_NOTCOMPTELE_FAIL';

export const RESET_PROFILE_ERROR = 'RESET_PROFILE_ERROR';

export const FETCH_DOCTOR_TITLE_SUCCESS = 'FETCH_DOCTOR_TITLE_SUCCESS';
export const FETCH_DOCTOR_DEGREE_SUCCESS = 'FETCH_DOCTOR_DEGREE_SUCCESS';
export const FETCH_DOCTOR_ACADEMIC_SUCCESS = 'FETCH_DOCTOR_ACADEMIC_SUCCESS';

export const FETCH_STATUS_RESET_PASSWORD = 'FETCH_STATUS_RESET_PASSWORD'; 

export const CHECK_REFCODE_REQUEST = 'refcode.CHECK_REFCODE_REQUEST';
export const CHECK_REFCODE_SUCCESS = 'refcode.CHECK_REFCODE_SUCCESS';
export const CHECK_REFCODE_FAILURE = 'refcode.CHECK_REFCODE_FAILURE';
export const CLEAR_REFCODE = 'refcode.CLEAR_REFCODE';
