import { BASE_API } from "../core/system.constants";

export const get_avatar_url = (url) => {
  if(!url){
    return process.env.PUBLIC_URL+"/assets/avatar.png";
  }else{
    if(url.startsWith('http')) return url;
    else return BASE_API + url;
  }
}
