import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

import './TextField.css';

export default class TextField extends Component{

    constructor(props){
        super(props);
        this.state = {
            classes: {
                input: ['input-field']
            },
            value: props.value ? props.value : ''
        }

        this._handleChange = this._handleChange.bind(this);
        this._toggleClass = this._toggleClass.bind(this);
    }


    _toggleClass(target, class_name, is_enabled){
        if(!is_enabled){
            this.setState((current_state) => {
                return {
                    classes: {
                        ...current_state.classes,
                        [target]: current_state.classes[target].filter((c)=> c !== class_name)
                    }
                }
            });
        }else{
            this.setState((current_state) => {
                return {
                    classes: {
                        ...current_state.classes,
                        [target]: current_state.classes[target].filter((c)=> c !== class_name).concat([class_name])
                    }
                }
            });
        }
    }

    _handleChange(e){
        if(e.target.value.length!==0){
           this._toggleClass('input', 'has-text', true);
        }else{
           this._toggleClass('input', 'has-text', false);
        }

        this.setState({
            value: e.target.value
        });

        this.props.onChange(e);
    }

    render(){
        const { type, label, name, errors, size } = this.props;

        const { value } = this.state;

        let input_placeholder_block = "";
        const has_error = !isEmpty(errors);

        if(!has_error){
            input_placeholder_block = <span className="default-text">{label}</span>
        }else{
            input_placeholder_block = <span className="invali-text">{errors[0]}</span>
        }
            

        return (
            <label className={`input-field-group${has_error ? " has-error" : ""}${size ? " "+size : ""}`}>
                <input className={this.state.classes.input.join(' ')} type={type?type:"text"} name={name}
                    value={value} onChange={this._handleChange} />
                <div className="input-placeholder">
                    {input_placeholder_block}
                </div>
            </label>
        )
    }
}

