// eslint-disable-next-line
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actionUser from "../../actions/user-action";
import * as actionAuth from "../../actions/auth-action";

import logo from '../../assets/CimigoHealth_Logo.png';

class Header extends React.Component {
  constructor() {
    super();
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
  }

  handleLogout() {
    this.props.logout();
    this.props.history.push('/');
  }

  renderAuthButtons(isAuth) {
    if (!isAuth) {
      return (
        <Link
          className="top-login-btn login-link a-text-color"
          to="/dang-nhap"
          title="Dang Nhap">
          Đăng nhập
        </Link>
      );
    }
  }

  renderDashboardSection(isAuth, username) {
    if (isAuth) {
      return (
        <div className="nav-item dropdown nav-item-color">
          {/* eslint-disable-next-line */}
          <a
            className="nav-link nav-item dropdown-toggle nav-item-point name"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <strong>{username}</strong>&nbsp;&nbsp;&nbsp;
            <i className="fas fa-bars" />
          </a>
          <div
            className="dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink">
            <Link
              className="dropdown-item dropdown-item-color"
              to="/bang-dieu-khien">
              Danh sách khảo sát
            </Link>
            <Link
              className="dropdown-item dropdown-item-color"
              to="/ho-so-ca-nhan">
              Thông tin tài khoản
            </Link>
            <Link
              className="dropdown-item dropdown-item-color"
              to="/doi-mat-khau">
              Đổi mật khẩu
            </Link>
            <button
              className="dropdown-item dropdown-item-color logout-btn"
              onClick={this.handleLogout}>
              <i className="fas fa-sign-out-alt" />
              &nbsp; Đăng xuất
            </button>
          </div>
        </div>
      );
    }
  }

  render() {
    const {fullname, isAuth} = this.props.auth;
      if(isAuth){
        this.props.getUserInfo();
      }

    return (
      <header className="top-header">
        <div className="wrapper">
          <Link to="/" className="top-logo">
            <img src={logo} alt="Logo" />
            {/* <span className="top-logo-text">Cimigo Health</span> */}
          </Link>
          <nav className="top-nav">
            <Link className="faq-link" to="/hoi-dap">
              FAQ
            </Link>
            {this.renderDashboardSection(isAuth, fullname)}
            {this.renderAuthButtons(isAuth)}
          </nav>
        </div>
      </header>
    );
  }
}

const actionsToProps = {
  getUserInfo: actionUser.getUserInfo,
  logout: actionAuth.logout
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    actionsToProps,
  )(Header),
);
