import React from "react";

export default class Terms extends React.Component {
  render() {
    return (
      <div>
        <br></br>
        <br></br>
        <p>
          Cimigo Health thực hiện các cuộc khảo sát bằng cách làm việc với các
          thành viên trong cộng đồng của Cimigo Health ("cộng đồng tham gia khảo
          sát"). Các điều khoản, điều kiện và hướng dẫn gắn liền với việc trở
          thành thành viên của cộng đồng tham gia khảo sát và việc sử dụng các
          dịch vụ Cimigo Health, phần mềm Cimigo Health và các dịch vụ liên quan
          (các "Dịch vụ ") sẵn có cho các thành viên trong cộng động tham gia
          khảo sát ("Thành viên trong panel" ) được nêu trong các Điều Khoản và
          Quy Định này. 
        </p>
        <p>
        Khi tham gia vào một cuộc khảo sát nghiên cứu thị trường
          của Cimigo Health bạn đồng ý tuân thủ các điều khoản, điều kiện và
          hướng dẫn trong các Điều Khoản và Quy Định này. Xin lưu ý rằng Cimigo
          Health có thể thay đổi các Điều Khoản và Quy Định bất cứ lúc nào mà
          không cần thông báo, và những thay đổi đó sẽ có hiệu lực vào ngày mà
          bản Điều Khoản và Quy Định đã sửa đổi được đăng trên trang web của
          Cimigo Health. Tham gia vào một bài khảo sát sau khi những thay đổi đó
          có hiệu lực có nghĩa là bạn chấp nhận tất cả những thay đổi. 
        </p>
        <p>
        Tham gia
          vào các cuộc khảo sát điều tra và trở thành thành viên Cimigo Health
          là miễn phí và dành cho các cán bộ y tế đồng ý với các Điều Khoản và
          Quy Định của chúng tôi đưa ra
        </p>
        <h4>1. Trách nhiệm của người sử dụng</h4>
        <p>
          Bạn đồng ý cung cấp cho Cimigo Health thông tin đăng ký một cách chính
          xác và đầy đủ, và luôn luôn cập nhật những chi tiết này. 
          

          
          {/* Nếu bạn không làm điều này, bạn đang làm
          trái các Điều Khoản và Quy Định mà chúng tôi đưa ra.  */}
 
        </p>
        <p>
        Bạn đồng ý
          không đăng ký làm Thành viên hội đồng với nhiều hơn một tên, không bao
          giờ tham gia cùng một khảo sát hai lần hoặc có được nhiều hơn một mật
          khẩu bằng bất kỳ cách nào. 
        </p>

<p>
Bạn tuyên bố rằng
          bạn không phải là một đối tác, cổ đông hoặc nhân viên của một công ty
          cung cấp phần mềm hoặc dịch vụ, dựa trên web hoặc các công nghệ khác,
          nhằm tiến hành nghiên cứu thị trường định tính hay định lượng từ quan
          điểm của khách hàng, khách hàng tiềm năng, nhân viên, người thụ hưởng
          hoặc các đối tượng mục tiêu khác (một "Đối thủ cạnh tranh của
          Cimigo"). 
</p>

        <p>
        Ngoài ra, bạn tuyên bố rằng bạn không phải là một thành viên
          của Hiệp hội nghiên cứu thị trường (quốc tế) cũng không phải của
          ESOMAR . Khi đăng ký để trở thành một Thành viên hội đồng, bạn sẽ nhận
          được một tên người dùng và mật khẩu. 
        </p>
      <p>
      Bạn có trách nhiệm bảo mật chi
          tiết tài khoản và mật khẩu của bạn. Bạn không thể thông báo cho những
          người khác mật khẩu của bạn. Bạn hoàn toàn chịu trách nhiệm cho bất kỳ
          hoạt động nào được thực hiện thông qua tài khoản của bạn. 
      </p>

        <p>
        Bạn đồng ý
          thông báo cho Cimigo Health ngay lập tức về trường hợp việc sử dụng
          trái phép tài khoản của bạn hoặc bất kỳ hành vi vi phạm khác về an
          ninh mà bạn biết được hoặc nghi ngờ.
        </p>

        <p>   Cimigo Health có thể gửi cho bạn,
          là một thành viên của Hội đồng nghiên cứu, một thư mời qua email tham
          gia vào một hoặc nhiều cuộc khảo sát. Nếu bạn quyết định tham gia vào
          một cuộc khảo sát, bạn phải làm hết sức mình để hoàn thành nó hết sức
          có thể.  </p>

<p>
Cimigo Health sẽ xem kết quả của bạn để đảm bảo bạn đã hoàn
          thành việc khảo sát và đã trả lời các câu hỏi một cách mà Cimigo
          Health cho là thỏa đáng.
</p>
        <p>
        Nếu các câu trả lời của bạn có trong các kết
          quả thu thập được mà Cimigo Health sẽ trao cho khách hàng đã mua cuộc
          khảo sát, Cimigo Health sẽ gửi bạn thù lao tham gia như đã ghi trong
          thư mời.         <i>Tuy nhiên, nếu Cimigo Health không sử dụng câu trả lời của
          bạn khi tập hợp kết quả để trao cho khách hàng vì lí do không đạt yêu
          cầu thì bạn sẽ không được thù lao này. 
          </i>
        </p>

        <p>
        Khi trở thành Thành viên hội
          đồng, bạn đã đồng ý với những điều kiện như sau: <i> không được gửi mail
          cho bên thứ ba hay công khai những thông tin về các cuộc khảo sát bao
          gồm nhưng không giới hạn bởi những câu hỏi bạn đã đọc, câu trả lời của
          bạn cho những câu hỏi, bản chất hoặc là nội dung của bất kỳ bài khảo
          sát nào mà bạn đã tham gia, trang web mà bạn đã truy cập, hoặc là
          những sản phẩm mà bạn đã sử dụng thử trong quá trình tham gia khảo
          sát. </i>
          
        </p>
        <p>
        Tất cả những nội dung, câu hỏi, và phần trả lời đều thuộc bản
          quyền của Cimigo Health. Bạn phải có trách nhiệm :
        </p>
        <ul>
          <li>          Không được đưa thông tin gây hiểu lầm hoặc
          thông tin sai lệch khi đăng kí hoặc trong các câu trả lời trong bài
          khảo sát. </li>
          <li>          Không được công khai những thông tin gây hiểu lầm hoặc thông
          tin sai lệch về Cimigo Health hoặc những bài khảo sát của công ty.</li>
          <li>
          Không khuyến khích bên thứ ba đưa Cimigo Health các thông tin sai lệch
          hoặc không chính xác trong quá trình đăng ký. 
          </li>
          <li>
          Không đòi hỏi phần
          thưởng cho các phần khảo sát bạn đã không hoàn thành. 
          </li>
          <li>
          Không yêu cầu
          bên thứ ba trả lời những bài khảo sát thay cho bạn. 
          </li>
          <li>
          Không được dùng
          phần mềm hoặc chương trình tự động để tạo ra câu trả lời cho những
          phần khảo sát. 
          </li>
          <li>
          Không đăng kí lại với Cimigo Health khi tài khoản của
          bạn đã bị xóa vì vi phạm các Điều Khoản và Quy Định của chúng tôi đưa
          ra.
          </li>
        </ul>
        <h4>2. Tuyên ngôn về quyền riêng tư</h4>
        <p>
          Khi đồng ý với những điều kiện trên, bạn cũng đã đồng ý cho phép
          Cimigo công bố và sử dụng thông tin cá nhân như đã nêu trong Chính
          sách bảo mật hiện hành của Cimigo Health.
        </p>
        <h4>3. Vi phạm các điều khoản và quy định</h4>
        <p>
          Nếu Cimigo Health có lý do hợp lý để tin rằng đã xảy ra vi phạm thực
          sự hoặc có nguy cơ xảy ra vi phạm đối với ý định thư hoặc các điều
          khoản và quy định, chúng tôi có quyền tạm thời trì hoãn hoặc chấm dứt
          vô thời hạn ngay lập tức các Dịch vụ Cimigo Health do cung cấp, nhằm
          ngăn chặn các hành vi bị cấm hoặc thực thi các biện pháp khác nếu xét
          thấy phù hợp.
        </p>
        <h4>4. Chấm dứt</h4>
        <p>
          Bạn có thể hủy bỏ tài khoản bất cứ lúc nào. Đây là phương tiện duy
          nhất của bạn khi bạn không hài lòng với những Dịch vụ, các Điều Khoản
          và Quy Định, hoặc những chính sách và hoạt động khác mà Cimigo Health
          có thể điều chỉnh hay sửa đổi. 
          
          

        </p>
<p>
Cimigo Health có thể chấm dứt tài
          khoản của bạn bất cứ lúc nào. Nếu như bạn không tham gia vào những Hội
          đồng của Cimigo Health và nếu bạn không trả lời các yêu cầu trong
          email nào hoặc không tham gia những nghiên cứu có sẵn, Cimigo Health
          có quyền đóng tài khoản của bạn. 
          
</p>
        <p>
        Cimigo Health có quyền hạn chế những Dịch vụ của người
          sử dụng và theo xem xét riêng, có quyền từ chối truy cập của bạn tới
          những Dịch vụ và xóa đi tất cả những thông tin và nội dung của tài
          khoản bất cứ lúc nào, có báo trước hoặc không báo trước và có hoặc
          không có lý do. 
        </p>
        <p>
        Liên hệ: Nếu bạn có thắc mắc thêm về Điều Khoản và Quy
          Định của chúng tôi, xin vui lòng liên hệ với chúng tôi qua địa chỉ
          email: <strong>anhdu@cimigo.com</strong>.
        </p>
        <br></br>
      </div>
    );
  }
}
