import { FETCH_SPECIALIST_SUCCESS } from './types';
import axiosService from '../services/axios.service';

const axiosInstance = axiosService.getBaseInstance();

const fetchSpecialistsSuccess = (specialists) => {
    return {
        type: FETCH_SPECIALIST_SUCCESS,
        specialists
    }
}

export const fetchSpecialists = () =>{
    return (dispatch) =>{
        axiosInstance.get('/specialists')
        .then(res => res.data)
        .then((specialists) =>{
            dispatch(fetchSpecialistsSuccess(specialists));
        })
    }
}
