import React from 'react';

import RegisterCodeContainer from '../components/register/RegisterCodeContainer';

class RegisterCodePage extends React.Component {
    render(){
        return(
            <RegisterCodeContainer/>
        );
    }
}

export default RegisterCodePage;
