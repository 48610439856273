import React from 'react';
import { Link } from 'react-router-dom';
import './ZaloAuth.css';

class ZaloAuth extends React.Component {

    componentDidMount(){
        document.addEventListener('ZaloObjectReady', this.initZalo);
    window.Zalo.init({
      version: '2.0',
      appId: process.env.REACT_APP_ZALO_API_APP_ID,
      redirectUrl: process.env.REACT_APP_ZALO_API_REDIRECT_URI,
    });

    // Broadcast an event when Zalo object is ready
    var zaloInitEvent = new Event('ZaloObjectReady');

    document.dispatchEvent(zaloInitEvent);
    }

    componentWillUnmount(){
        document.removeEventListener('ZaloObjectReady', this.initZalo);
    }

    initZalo = () => {
        this.Zalo = window.Zalo;
        this.checkLoginStatus();
    }

    checkLoginStatus = () => {
        this.Zalo.getLoginStatus(response => {
            if(response.status === 'connected'){
                this.Zalo.api('/me',
                    'GET',
                    { fields: 'id, name, picture, birthday, gender' },
                    (user) => {
                        let info = {
                            oauth_code : this.props.oauth_code,
                            user: user,
                            ref_code: this.props.state
                        }
                        this.props.onLogin(info, 'zalo');
                    });
            }
        });
    }

    render(){
        if(!this.props.isLoading && this.props.errors){
            return <div className="zalo-auth">
                <p>Có lỗi xảy ra lúc login bằng Zalo ({this.props.errors}), vui lòng thử hình thức đăng nhập khác. <Link to="/">Trở về trang chủ</Link></p></div>
        }

        return(
            <div className="zalo-auth">
                    <p>Đang đang nhập...</p>
            </div>
        );
    }
}

export default ZaloAuth;

