import React from 'react';
import TextField from '../ui/TextField';
import { Redirect } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import validator from 'validator';

import SocialLogin from '../social-login/SocialLogin';

class RegisterCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      register_code: '',
      errors: {},
      is_submitting: false,
    };
  }

  handleChange = e => {
    const {name, value} = e.target;

    this.setState(current_state => {
      return {
        ...current_state,
        [name]: value,
        errors: {
          ...current_state.errors,
          [name]: [],
        },
      };
    });
  };

  validateInput(data) {
    let errors = {};

    if (validator.isEmpty(data.register_code)) {
      errors.register_code = ['Mã giới thiệu bắt buộc'];
    }

    return {
      errors,
      is_valid: isEmpty(errors),
    };
  }

  _isValid() {
    const {errors, is_valid} = this.validateInput(this.state);

    if (!is_valid) {
      this.setState({errors});
    }

    return is_valid;
  }

  checkRefCode = e => {
    e.preventDefault();
    if (this._isValid()) {
      this.setState({errors: {}, is_submitting: true});
      this.props.checkRefCode(this.state.register_code);
    }
  };
  render() {
    const {register_code, errors} = this.state;
    const {is_code_valid, error, ref_code, isAuth} = this.props;

    if(isAuth){
        return <Redirect to={{ pathname: "/bang-dieu-khien" }} />;
    }

    if (is_code_valid && !isAuth) {
      return (
        <div className="register-with-social">
          <SocialLogin 
            title="Chọn hình thức đăng ký"
            zalo_title="Đăng ký bằng Zalo"
            fb_title="Đăng ký bằng Facebook"
            gg_title="Đăng ký bằng Gmail"
            ref_code={ref_code}
            onLogin={this.props.socialLogin}/>
        </div>
      )
    }

    return (
      <div>
        {!is_code_valid && (
          <div className="register-code-section">
            <h2>Vui lòng nhập mã giới thiệu</h2>
            <form className="code-form" onSubmit={this.checkRefCode}>
              <div className="code-input-group">
                <TextField
                  name="register_code"
                  label="Mã giới thiệu"
                  value={register_code}
                  onChange={this.handleChange}
                  errors={errors.register_code}
                  size="large"
                />
              </div>

                <div className="summary-error">
                    { !isEmpty(error) ? error.message : '' }
                </div>
              <div className="form-action">
                <button
                  type="submit"
                  className="submit-code-btn action-btn blue">
                  Tiếp tục
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default RegisterCode;
