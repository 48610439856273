import React from 'react';

import AdditionalInfoContainer from '../components/register/AdditionalInfoContainer';

class AdditionalInfoPage extends React.Component {
    render(){
        return(
            <AdditionalInfoContainer/>
        );
    }
}

export default AdditionalInfoPage;
