import React from "react";
import { Redirect } from 'react-router-dom';
import RegisterForm from "./RegisterForm";
import * as actionAuth from '../../actions/auth-action';
import { destroy, isPristine } from 'redux-form';
import { connect } from 'react-redux';

import SnackBar from '../shared/snackBar';

import { RegisterModal } from "./RegisterModal";

class Register extends React.Component {

  constructor() {
    super();

    this.state = {
      snackBar: {
        message: '',
        variant: '',
        open: false,
      },
      modal: {
        open: false
      },
      done: false
    }

    this.registerUser = this.registerUser.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.cancelSnackBar = this.cancelSnackBar.bind(this);
  }

  cancelSnackBar = () => {
    this.setState({
      snackBar: { 
        ...this.state.snackBar,
        open: false
      }
    })
  }

  cancelModal() {
    this.setState({
      modal: { open: false },
      done: true
    })

  }

  registerUser(userData) {
    if (userData.city == null) {
      userData.city = this.props.cities[0]._id;
    }
    if (userData.specialist == null) {
      userData.specialist = this.props.specialists[0]._id;
    }
    if (userData.doctor_title == null) {
      userData.doctor_title = this.props.doctorTitles[0]._id;
    }
    if (userData.doctor_academic == null) {
      userData.doctor_academic = this.props.doctorAcademics[0]._id;
    }
    userData.status = 1;
    userData.ref_code = this.props.ref_code.code;
    
    actionAuth.addProfile(userData).then(
      registered => {
        this.props.destroy('RegisterForm');
        this.setState({
          modal: {
            open: true
          }
        })
      },
      errors => {
        this.setState({
          snackBar:{
            message: errors,
            variant: 'error',
            open: true
          }
        })
      }
    )
  }

  contains (arr, obj) {
    for(var i = 0; i < arr.length; i++ ){
      if(arr[i]._id === obj) return true;
    }
    return false;
  }

  render() {
    const { done } = this.state;
    if(done){
      return <Redirect to="/bang-dieu-khien" />
    }
    const { cities, specialists, doctorTitles, doctorAcademics } = this.props;
    
    if(cities.length > 0 ){
      if(!this.contains(cities, "null")){
        cities.push({
          _id: "null",
          value: "0",
          name: "Vui lòng chọn thành phố"
        });
      }
   
    }
    if(specialists.length > 0 ){
      if(!this.contains(specialists, "null")){
        specialists.push({
          _id: "null",
          value: "0",
          name: "Vui lòng chọn chuyên khoa"
        })
      }
    }
    if(doctorTitles.length > 0 ){
      if(!this.contains(doctorTitles, "null")){
        doctorTitles.push({
          _id: "null",
          value: "0",
          name: "Vui lòng chọn chức danh"
        })
      }
    }
    if(doctorAcademics.length > 0 ){
      if(!this.contains(doctorAcademics, "null")){
        doctorAcademics.push({
          _id: "null",
          value: "0",
          name: "Vui lòng chọn học hàm"
        })
      }
    }
    return (
      <section id="register" className="container">
        <div className="request-form">
          <h1 id="title-register">Đăng ký thông tin</h1>
          <RegisterForm 
            submitCb={this.registerUser} 
            cities={cities} 
            specialists={specialists} 
            doctorTitles={doctorTitles} 
            doctorAcademics={doctorAcademics} 
          />
        </div>

        <SnackBar
          message={this.state.snackBar.message}
          variant={this.state.snackBar.variant}
          open={this.state.snackBar.open}
          handleCloseCb={this.cancelSnackBar} />

        <RegisterModal open={this.state.modal.open} closeModal={this.cancelModal} />
      </section>
    );
  }
}


const mapStateToProps = state => {
  return {
    RegisterForm: state.form.RegisterForm,
    pristine: isPristine('RegisterForm')(state)
  };
};

export default connect(mapStateToProps, { destroy })(Register);
