import React from "react";
import * as actionAuth from "../../actions/auth-action";
import SnackBar from "../shared/snackBar";
import ResetPasswordForm from "./ResetPasswordForm";
import {Link} from "react-router-dom";
import { connect } from "react-redux";

class ResetPassword extends React.Component {
  constructor() {
    super();

    this.state = {
      snackBar: {
        message: "",
        variant: "",
        open: false
      }
    };

    this.resetPassword = this.resetPassword.bind(this);
    this.cancelSnackBar = this.cancelSnackBar.bind(this);
  }

  componentWillMount() {
    const data = {
      userID: this.props.userID,
      key: this.props.resetKey
    };
    this.props.dispatch(actionAuth.checkResetPassword(data));
  }


  cancelSnackBar = () => {
    this.setState({
      snackBar: {
        ...this.state.snackBar,
        open: false
      }
    });
  };

  resetPassword = userData => {
    const data = {
      userID: this.props.userID,
      key: this.props.resetKey,
      password: userData.newPassword
    };
    actionAuth.resetPassword(data).then(
      res => {
        this.setState({
          snackBar: {
            message: "Đổi mật khẩu thành công",
            variant: "success",
            open: true
          }
        });
      },
      error => {
        console.log(error);
        this.setState({
          snackBar: {
            message: error,
            variant: "error",
            open: true
          }
        });
      }
    );
  };


  renderHTML(checkResetPassword) {
    if (checkResetPassword === {}) {
      return (
        <div className="text-center">
          <h1>Vui lòng chờ trong giây lát...</h1>
        </div>
      );
    } else if (checkResetPassword.status === "EXPIRED") {
      return (
        <div className="alert alert-danger" role="alert">
          <div className="text-center">
            <h3>Link thiết lập lại mật khẩu đã hết hạn.</h3>
            <h5>Vui lòng nhấn <Link to="/quen-mat-khau">vào đây</Link> để lấy lại mật khẩu.</h5>
          </div>
        </div>
      );
    } else if (checkResetPassword.status === "NOT_FOUND") {
        return (
          <div className="alert alert-danger" role="alert">
            <div className="text-center">
              <h3>Link thiết lập lại mật khẩu không được tìm thấy.</h3>
              <h5>Vui lòng nhấn <Link to="/quen-mat-khau">vào đây</Link> để lấy lại mật khẩu.</h5>
            </div>
          </div>
        );
      }else if(checkResetPassword.status === "SUCCESS"){
        return(
            <div className="text-center mt-10 mb-5">
            <div className="col-md-4 center-block">
              <h3 className="uppercase">nhập mật khẩu mới</h3>
    
              <div className="text-left">
                <ResetPasswordForm submitCb={this.resetPassword} />
              </div>
              <div className="text-left">
                <SnackBar
                  message={this.state.snackBar.message}
                  variant={this.state.snackBar["variant"]}
                  open={this.state.snackBar["open"]}
                  handleCloseCb={this.cancelSnackBar}
                />
              </div>
            </div>
          </div>
        );
      }
  }
  render() {
    const { checkResetPassword } = this.props;
    return (
      <React.Fragment>
        {this.renderHTML(checkResetPassword)}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    checkResetPassword: state.checkResetPassword.status
  };
}

export default connect(mapStateToProps)(ResetPassword);
