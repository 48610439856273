import { FETCH_SPECIALIST_SUCCESS } from '../actions/types';

const INITIAL_SPECIALISTS = {
    specialists : {
        data: []
    }
}

export const specialistReducer = (state = INITIAL_SPECIALISTS.specialists, action) => {
    switch(action.type){
        case FETCH_SPECIALIST_SUCCESS:
            return { ...state, data: action.specialists };
        default :
            return state;
    }
};