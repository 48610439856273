import React from "react";
import { UploadImage } from "../components/upload-image/UploadImage";

export default class UploadImagePage extends React.Component {
  render() {
    return (
      <section id="uploadImage" className="container mt-8">
        <UploadImage />
      </section>
    );
  }
}
