import React from 'react';
import * as actionUploadImage from '../../actions/uploadImage-action';
import {BASE_API} from '../../core/system.constants';
import imageAvatar from '../../assets/avatar_2x.png';
export class ProfileAvatar extends React.Component {
  constructor() {
    super();

    this.setupReader();

    this.state = {
      selectedFile: undefined,
      imageBase64: undefined,
      avatar: undefined,
      originAvatar: undefined,
      isActive: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setOriginValue();
  }

  setOriginValue() {
    const {avatar} = this.props;

    this.setState({
      avatar: avatar,
      originAvatar: avatar,
    });
  }

  setupReader() {
    this.reader = new FileReader();
    this.reader.addEventListener('load', event => {
      const imageBase64 = event.target.result;
      this.setState({
        imageBase64: imageBase64,
        avatar: imageBase64,
        isActive: true,
      });
    });
  }

  onChange(event) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.setState({selectedFile});
      this.reader.readAsDataURL(selectedFile);
    }
  }

  cancelAvatar(e) {
    e.preventDefault();

    const {originAvatar} = this.state;
    this.setState({
      avatar: originAvatar,
      imageBase64: undefined,
      isActive: false,
    });

    this.inputElement.value = null;
    const changeEvent = new Event('change');
    this.inputElement.dispatchEvent(changeEvent);
  }

  onSuccess(uploadImage) {
    this.setState({
      pending: false,
      status: 'OK',
      error: '',
      isActive: false,
      originAvatar: BASE_API + uploadImage,
      avatar: BASE_API + uploadImage,
    });
  }

  onError(error) {
    this.setState({
      pending: false,
      status: 'FAIL',
      error: error,
    });
  }

  upload() {
    const {selectedFile} = this.state;
    const {updateEntity} = this.props;

    if (selectedFile) {
      actionUploadImage.uploadAvatar(selectedFile).then(
        uploadImage => {
          const userData = {
            data: {avatar: uploadImage},
            type: 'user',
          };
          this.onSuccess(uploadImage);
          updateEntity(userData);
        },
        error => {
          this.onError(error);
        },
      );
    }
  }

  renderActionButton() {
    const {isActive} = this.state;

    if (isActive) {
      return (
        <React.Fragment>
          <div>
            <button
              onClick={() => this.upload()}
              className="btn btn-primary btn-editable"
              type="button">
              <i className="far fa-save" />
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              onClick={e => this.cancelAvatar(e)}
              className="btn btn-danger btn-editable"
              type="button">
              <i className="fas fa-times" />
            </button>
          </div>
        </React.Fragment>
      );
    }
  }

  renderSpinningCircle() {
    const {pending} = this.props;

    if (pending) {
      return (
        //   <div className="img-loading-overlay">
        //     <div className="img-spinning-circle" />
        //   </div>
        // <div id="editableComponent" className="sp sp-circle"></div>
        <div>Loading</div>
      );
    }
  }

  render() {
    const {avatar} = this.state;

    return (
      <React.Fragment>
        <div className="avatar-upload">
          <div className="avatar-edit">
            <input
              type="file"
              id="imageUpload"
              accept=".png, .jpg, .jpeg"
              onChange={this.onChange}
              ref={input => {
                this.inputElement = input;
              }}
            />
            <label htmlFor="imageUpload" />
          </div>
          <div className="avatar-preview">
            <div id="imagePreview">
              {avatar === `${BASE_API}null` && (
                <img
                  src={imageAvatar}
                  className="avatar avatarWH rounded-circle img-thumbnail"
                  alt="avatar"
                />
              )}
              {avatar !== `${BASE_API}null` && (
                <img
                  src={avatar}
                  className="avatar avatarWH rounded-circle img-thumbnail"
                  alt="avatar"
                />
              )}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <label className="avatar-upload-text" htmlFor="imageUpload">
            Tải lên một bức ảnh khác...
          </label>
        </div>

        {this.renderActionButton()}
        {this.renderSpinningCircle()}
      </React.Fragment>
    );
  }
}
