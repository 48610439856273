import React from "react";
import ResetPassword from "../components/reset-password/ResetPassword";
import { withRouter } from "react-router-dom";

class ResetPasswordPage extends React.Component {
  render() {
    let userID = this.props.match.params.userID;
    let key = this.props.match.params.key;

    return (
      <section id="resetPassword" className="container mt-10">
      <ResetPassword 
                    userID={userID} 
                    resetKey={key}
                ></ResetPassword>
      </section>
    );
  }
}

export default withRouter(ResetPasswordPage);