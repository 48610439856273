export const SURVEY_URL = 'https://www.surveygizmo.com/s3/4756956/4cf76ccfeae5';
export const API_VERSION = '/api/v1';

var _baseAPI = undefined;
if(process.env.NODE_ENV === 'production')
{
    _baseAPI = 'https://healthapi.cimigo.com';
}
else {
    _baseAPI = 'http://localhost:8090';
}

export const BASE_API = _baseAPI;
export const USERNAME_FORGOT_PASSWORD = "USERNAME_FORGOT_PASSWORD"; 