import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { DPInput } from '../shared/form/DPInput';

const validate = values => {
  const errors = {}
  const requiredFields = [
    'username',
    'password'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Không được bỏ trống'
    }
  })
  // if (
  //   values.email &&
  //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  // ) {
  //   errors.email = 'Email không hợp lệ'
  // }
  return errors
}

const LoginForm = props => {


  const { handleSubmit, submitCb } = props;
  return (
    <form onSubmit={handleSubmit(submitCb)}>
      <Field name="username" component={DPInput} label="Email/Số điện thoại" type="text" className="material-ui-form" />
      <Field name="password" component={DPInput} label="Mật khẩu" type="password" className="material-ui-form" />
      <button
        className='action-btn blue register-btn btn-block mt-4'
        type='submit'>
        Đăng nhập
      </button>
    </form>
  );
};

export default reduxForm({
  form: 'LoginForm',
  validate
})(LoginForm);
