import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NumberFormat from "react-number-format";

const CustomTableCell = withStyles(theme => ({
  head: {
    //backgroundColor: theme.palette.primary.main,
    backgroundColor: "#f2f2f2",
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: "14px"
  },
  body: {
    fontSize: 13
  }
}))(TableCell);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      //backgroundColor: theme.palette.background.default
      backgroundColor: "#f9f9f9"
    }
  }
});



function CustomizedTable(props) {
  const { classes, surveys,  user, functionDeny, functionJoin, deny } = props;
  const userId = user._id;

  function update(survey_id){
	  if(window.confirm("Bạn có chắc chắn muốn từ chối?")){
		functionDeny(survey_id);
	  }
  }

  function join(survey_id, link){
    functionJoin(survey_id, link);
  }


  function contains (arr, obj) {
    for(var i = 0; i < arr.length; i++ ){
      if(arr[i]._id === obj) return true;
    }
    return false;
  }

  function loadCompensation(survey, user){
    var compensation = 0;
    var sortByOrder = survey.compensations.sort(function(a,b) { return a.order - b.order});

    sortByOrder.forEach(s => {
      var count = 0;
      s.criterias.forEach(c => {
        if(c["key"] && c["key"] === "city"){
          if(contains(c.values, user.doctor_profile.city._id)){
            count++;
          }
        }
        if(c["key"] && c["key"] === "specialist"){
          if(contains(c.values, user.specialist.city._id)){
            count++;
          }
        }
        if(c["key"] && c["key"] === "doctor_title"){
          if( contains(c.values, user.doctor_profile.chuc_danh._id)){
            count++;
          }
        }
        if(c["key"] && c["key"] === "doctor_academic"){ 
          if(contains(c.values, user.doctor_profile.hoc_ham._id)){
            count++;
          }
        }
      });
      //console.log(`count : ${count} - value : ${s.value}`);
      if(count === s.criterias.length){
        compensation = +s.value;
      }
    });

    if(compensation === 0){
      compensation = survey.default_compensation;
    }
    return compensation;
  }

  function renderAction(survey_id, link, status, users_deny, users_complete, users_doing) {
    var is_complete = users_complete.includes(userId);
    var is_deny = users_deny.includes(userId);
    var is_doing = users_doing.includes(userId);

    if (is_complete === false && is_deny === false && is_doing === false) {
      if(status === 1){
        return (
          <React.Fragment>
            <button type="button" className="btn btn-dp-default-rev btn-survey" 
            onClick={() => update(survey_id)}>
              Từ chối
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-dp-primary btn-survey"
              onClick={() => {
                join(survey_id, `${link}${survey_id}/${userId}`);
              }}
            >
              Tham gia
            </button>
          </React.Fragment>
        );
      }
      else if(status === 0){
        return (
          <div className="text-expired"> &nbsp;&nbsp;Đã hết hạn &nbsp;&nbsp;</div>
        );
      }     
    }else if(is_complete === false && is_deny === true && is_doing === false){
      return (
        <div className="btn-dp-danger text-white btn btn-survey"> &nbsp;&nbsp;Đã từ chối &nbsp;&nbsp;</div>
      );
    }
    else if(is_complete === true && is_deny === false && is_doing === false){
      return (
        <div className="btn-dp-success text-white btn btn-survey"> &nbsp;&nbsp;Đã hoàn thành &nbsp;&nbsp;</div>
      );
    }
    else if(is_complete === false && is_deny === false && is_doing === true){
      if(status === 1){
        return (
          // <div className="bg-success text-white btn btn-width-177">Đã hoàn thành</div>
          <button
          type="button"
          className="btn btn-dp-warning  btn-survey"
          onClick={() => {
            window.location = `${link}${survey_id}/${userId}`;
          }}
        >
           &nbsp;&nbsp;Tiếp tục &nbsp;&nbsp;
        </button>
        );
      }
      else if(status === 0){
        return (
          <div className="text-expired"> &nbsp;&nbsp;Đã hết hạn &nbsp;&nbsp;</div>
        );
      }
    }
  }

  return (
    <div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell className="width-table-16">Khảo sát</CustomTableCell>
              <CustomTableCell className="width-table-20">Trạng thái</CustomTableCell>
              <CustomTableCell className="width-table-13">Thù lao</CustomTableCell>
              <CustomTableCell className="width-table-15">Ngày hết hạn</CustomTableCell>
              <CustomTableCell>Mô tả</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveys.map(row => {
              return (
                <TableRow className={classes.row} key={row._id}>
                  <CustomTableCell component="th" scope="row">
                    {row.alias + " " + row.name}
                  </CustomTableCell>
                  <CustomTableCell>
                    { (deny.id !== row._id) &&
                      renderAction(row._id, row.url, row.status, row.users_deny, row.users_complete, row.users_doing)
                    }
                    { (deny.loading && deny.id === row._id) &&
                      <div className="sp sp-circle ml-5"></div>
                    }
                  </CustomTableCell>


                  <CustomTableCell>
                    <NumberFormat
                      value={loadCompensation(row, user)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    {" đ"}
                  </CustomTableCell>

                  <CustomTableCell>
                    {new Date(row.expired_date).toLocaleDateString()}
                  </CustomTableCell>

                  <CustomTableCell>{row.description}</CustomTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>

      {surveys.length === 0 && (
        <div className="alert alert-danger mt-2 text-center">
          Không có khảo sát nào trong năm nay.
        </div>
      )}
    </div>
  );
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomizedTable);
