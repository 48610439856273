import React from "react";
import { connect } from "react-redux";
import { destroy, isPristine } from "redux-form";
import SnackBar from "../shared/snackBar";
import ConfirmOTPForm from "./ConfirmOTPForm";
import { USERNAME_FORGOT_PASSWORD } from "../../core/system.constants";
import * as actionAuth from "../../actions/auth-action";
import { Redirect } from "react-router-dom";

class ConfirmOTP extends React.Component {
  _id;
  reset_password_key;
  constructor() {
    super();

    this.state = {
      snackBar: {
        message: "",
        variant: "",
        open: false
      },
      clipLoader: {
        loading: false
      },
      redirect: false
    };
    this.cancelSnackBar = this.cancelSnackBar.bind(this);
    this.confirmOTP = this.confirmOTP.bind(this);
  }

  cancelSnackBar = () => {
    this.setState({
      snackBar: {
        ...this.state.snackBar,
        open: false
      }
    });
  };

  confirmOTP(data) {
    const userdata = {
      username: localStorage.getItem(USERNAME_FORGOT_PASSWORD),
      otp: data.otp
    };

    actionAuth.checkOTP(userdata).then(
      res => {
        this._id = res.user_id;
        this.reset_password_key = res.reset_password_key;
        this.setState({
          redirect: true
        });
      },
      error => {
        this.setState({
          redirect: false,
          snackBar: {
            message: error,
            variant: "error",
            open: true
          }
        });
      }
    );
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      const url = `/reset-password/${this._id}/${this.reset_password_key}`;
      return <Redirect to={url} />;
    }
  };

  render() {
    const { message, variant, open } = this.state.snackBar;
    const { loading } = this.state.clipLoader;
    const username = localStorage.getItem(USERNAME_FORGOT_PASSWORD);
    return (
      <div className="text-center mt-10 mb-5">
        <div className="col-md-4 center-block">
          <h3 className="uppercase">Nhập mã OTP</h3>
          <h6>
            Mã OTP đã được gửi. Vui lòng kiểm tra email hoặc tin nhắn điện thoại
          </h6>
          <div>
            <button
              className="btn btn-outline-secondary btn-pill mt-3"
              type="button"
            >
              <span className="user-otp">
                <i className="fa fa-user-circle-o" aria-hidden="true" />
                &nbsp;&nbsp;
                <strong>{username}</strong>
              </span>
            </button>
          </div>
          {this.renderRedirect()}
          <div className="text-left mt-3">
            <ConfirmOTPForm submitCb={this.confirmOTP} indicator={loading} />
          </div>

          <div className="text-left">
            <SnackBar
              message={message}
              variant={variant}
              open={open}
              handleCloseCb={this.cancelSnackBar}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pristine: isPristine("ConfirmOTPForm")(state)
  };
};

export default connect(
  mapStateToProps,
  { destroy }
)(ConfirmOTP);
