import React from "react";
import { EditableComponent } from "./EditableComponent";

export class EditableSelect extends EditableComponent{
    renderOptions(options){
        return options.map((option, index) => <option key={index} value={option._id}> {option.name} </option>)
    }

    renderLabel(){
        const { label } = this.props;
        return(
            <label><strong>{label}&nbsp;</strong></label>
        );
    }

    renderComponentView(){
        const {value, isActive } = this.state;
        const {className , options, fieldName, isLoading} = this.props;

        if(isActive){
            return (
                <React.Fragment>
                    <select onChange={(event) => this.handlChange(event)}
                        value={value}
                        className={className}>
                        {this.renderOptions(options)}    
                    </select>
                    <button onClick={() => this.update()} 
                            className="btn btn-primary btn-editable" 
                            type="button">
                            <i className="far fa-save"></i>
                    </button>
                    <button onClick={() => this.disableEdit()} 
                            className="btn btn-danger btn-editable" 
                            type="button">
                            <i className="fas fa-times"></i>
                    </button>
                </React.Fragment>
            );
        }

        if(isLoading){
            return (
                <React.Fragment>
                    <div className="sp sp-circle"></div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <span>{fieldName}</span>
                <button onClick={() => this.enableEdit()} 
                         className="btn-dp btn-editable" 
                        type="button">
                            <i className="far fa-edit iconSize14"></i>
                </button>
            </React.Fragment>
        );
    }

    render(){
        return(
            <div id="editableComponent" className="mb-2">
                {this.renderLabel()}
                {this.renderComponentView()}
            </div>
        );
    }
}