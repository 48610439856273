import React, { Component } from "react";
import Profile from "../components/profile/Profile";

export default class ProfilePage extends Component {
  render() {
    return (
      <section id="profile" className="container mt-5">
        <Profile />
      </section>
    );
  }
}
