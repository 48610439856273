import { FETCH_CITIES_SUCCESS } from '../actions/types';

const INITIAL_CITIES = {
    cities : {
        data: []
    }
}

export const cityReducer = (state = INITIAL_CITIES.cities, action) => {
    switch(action.type){
        case FETCH_CITIES_SUCCESS:
            return { ...state, data: action.cities };
        default :
            return state;
    }
};