import { UPDATE_PROFILE_FAIL, UPDATE_PROFILE_SUCCESS, RESET_PROFILE_ERROR ,
   FETCH_PROFILE_FAIL, FETCH_PROFILE_SUCCESS,
    UPDATE_DOCTOR_PROFILE_SUCCESS, UPDATE_DOCTOR_PROFILE_FAIL} from "./types";
import axiosSerivce from "../services/axios.service";

const axiosInstance = axiosSerivce.getInstance();

const updateProfileSuccess = (updateProfile) =>{
    return {
        type: UPDATE_PROFILE_SUCCESS,
        user: updateProfile
    }
}

const updateProfileFail = (error) =>{
    return {
        type: UPDATE_PROFILE_FAIL,
        error
    };
}

const fetchProfileFail = (error) => {
    return {
        type: FETCH_PROFILE_FAIL,
        error
    };
}

const fetchProfileSuccess = (profiles) => {
    return {
        type: FETCH_PROFILE_SUCCESS,
        profiles
    };
}


const updateDoctorProfilSuccess = (updateProfile) =>{
    return {
        type: UPDATE_DOCTOR_PROFILE_SUCCESS,
        user: updateProfile
    }
}

const updateDoctorProfilFail = (error) =>{
    return {
        type: UPDATE_DOCTOR_PROFILE_FAIL,
        error
    };
}


// const fetchProfileNotCompleteFail = (error) => {
//     return {
//         type: FETCH_PROFILE_NOTCOMPTELE_FAIL,
//         error
//     };
// }

// const fetchProfileNotCompleteSuccess = (profiles) => {
//     return {
//         type: FETCH_PROFILE_NOTCOMPTELE_SUCCESS,
//         profiles
//     };
// }

export const getUserInfo = () => {
    return (dispatch) => {
        axiosInstance.get('/users/info').then(res => res.data).then(
            (user) => { 
                dispatch(updateProfileSuccess(user))
            }
        )
    }
}

export const updateProfile = (userData) => dispatch =>{
   return axiosInstance.patch('/users/profile/update', userData)
            .then(res => res.data)
            .then(success =>{
                axiosInstance.get('/users/info').then(res => res.data).then(
                    (user) => { 
                        dispatch(updateProfileSuccess(user))
                    }
                )
            })
            .catch((response) => {
                //console.log( Promise.reject(response));
                dispatch(updateProfileFail(response.data.message));
                //dispatch(updateProfileFail(response));
            })
}

export const updateDoctorProfile = (userData) => dispatch =>{
    return axiosInstance.post('/doctorProfiles/update', userData)
             .then(res => res.data)
             .then(success =>{
                 axiosInstance.get('/users/info').then(res => res.data).then(
                     (user) => { 
                         dispatch(updateDoctorProfilSuccess(user))
                     }
                 )
             })
             .catch(({response}) => {
                 dispatch(updateDoctorProfilFail(response.data.message));
             })
 }
 


export const resetProfileError = () =>{
    return {
        type: RESET_PROFILE_ERROR
    }
}

export const getProfiles = () => {
    return (dispatch) =>{
        axiosInstance.get('/profiles/not-complete').then(res => res.data).then(
          (profiles) => { dispatch(fetchProfileSuccess(profiles))}
        ).catch(({response}) => {
            dispatch(fetchProfileFail(response.data.message));
        })
      }
}

export const getListTitleProfile = () => {
    return axiosInstance.get('/profiles/title').then(
        res => res.data,
        err => Promise.reject(err.response.data.message))
}

// export const getProfilesNotCompelete = () => {
//     return (dispatch) =>{
//         axiosInstance.get('/profiles/not-complete').then(res => res.data).then(
//           (profiles) => { 
//               dispatch(fetchProfileSuccess(profiles))
//             }
//         ).catch(({response}) => {
//             dispatch(fetchProfileFail(response.data.message));
//         })
//       }
// }
