import React, { Component } from 'react';

import HomeMainSection from '../components/home/MainSection';

export default class HomePage extends Component {
    render(){
        return(
            <React.Fragment>
                <HomeMainSection />
            </React.Fragment>
        )
    }
}
