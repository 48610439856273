import React from "react";
import Dashboard from "../components/dashboard/Dashboard";

export default class DashboardPage extends React.Component {
  render() {
    return (
      <section id="dashboard" className="container" >
        <Dashboard />
      </section>
    );
  }
}
