import { FETCH_SURVEY_SUCCESS } from './types';
import axiosService from '../services/axios.service';

//const axiosBaseInstance = axiosService.getBaseInstance();
const axiosInstance = axiosService.getInstance();

const fetchSurveysSuccess = (surveys) =>{
    return {
        type: FETCH_SURVEY_SUCCESS,
        surveys
    }
}


export const fetchSurveys = () =>{
    return (dispatch) =>{
        axiosInstance.get('/surveys/getAll/byUser')
            .then(res => res.data)
            .then((surveys) =>{
                dispatch(fetchSurveysSuccess(surveys));
            });
    }
}

export const surveyComplete = (surveyId) => {
    return axiosInstance.post('/surveys/complete/' + surveyId).then(
        res => res.data,
        err => Promise.reject(err.response.data.message)
    )
}

export const surveyJoin = (surveyId) => {
    return axiosInstance.post('/surveys/join/' + surveyId).then(
        res => res.data,
        err => Promise.reject(err.response.data.message)
    )
}

export const surveyDeny = (surveyId) => dispatch => {
    return axiosInstance.post('/surveys/deny/' + surveyId)
        .then(res => res.data)
        .then(success => {
            axiosInstance.get('/surveys/getAll/byUser')
                .then(res => res.data)
                .then((surveys) => {
                    dispatch(fetchSurveysSuccess(surveys));
                })
        })
}
