import React from "react";
import ChangePassword from "../components/change-password/ChangePassword";

export default class ChangePasswordPage extends React.Component {
  render() {
    return (
      <section id="changePassword" className="container mt-5">
        <ChangePassword />
      </section>
    );
  }
}
