import React from "react";
import Contact from "../components/contact/Contact";

export default class ContactPage extends React.Component {
    componentWillMount(){
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
      
    render() {
        return (
            <section id="contact" className="container mt-8">
                <Contact></Contact>
            </section>
        );
    }
}