import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
});

function renderMessageProfile(isCompleteProfile, externalId, classes) {
  if (!isCompleteProfile) {
    return (
      <div className="mb-3 pt-1 pb-1">
        <Paper
          className={classNames(classes.root, "text-center")}
          elevation={1}
        >
          <Typography variant="h6" component="h6">
          Vui lòng nhấn <Link to={`/ho-so-ca-nhan`} className="a-dp-default">vào đây</Link> để hoàn thành thông tin tài khoản.
          </Typography>
        </Paper>
      </div>
    );
  }
}

// function renderMessageLicense(isUplodatedLicense, classes){
//   if(!isUplodatedLicense){
//     return (
//       <div  className="mb-4">
//       <Paper
//         className={classNames(classes.root, "text-center")}
//         elevation={1}
//       >
//         <Typography variant="h6" component="h6">
//           Tài khoản chưa upload chứng chỉ hành nghề. Vui lòng click <Link to='/upload-image'>vào đây</Link> hình
//           chứng chỉ hành nghề          
//         </Typography>
//       </Paper>
//     </div>
//     );
//   }
// }

function PaperSheet(props) {
  const { classes, isCompleteProfile, externalId } = props;

  return (
   <React.Fragment>
     {renderMessageProfile(isCompleteProfile, externalId, classes)}
        {/* {renderMessageLicense(isUplodatedLicense, classes)} */}
   </React.Fragment>
  );
}

PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PaperSheet);
