import { FETCH_DOCTOR_TITLE_SUCCESS,
    FETCH_DOCTOR_ACADEMIC_SUCCESS,
    FETCH_DOCTOR_DEGREE_SUCCESS } from '../actions/types';

const INITIAL_DOCTOR = {
    doctorTitles : {
        data: []
    },
    doctorDegrees : {
        data: []
    },
    doctorAcademics : {
        data: []
    }
}

export const doctorTitleReducer = (state = INITIAL_DOCTOR.doctorTitles, action) => {
    switch(action.type){
        case FETCH_DOCTOR_TITLE_SUCCESS:
            return { ...state, data: action.doctorTitles };
        default :
            return state;
    }
};

export const doctorDegreeReducer = (state = INITIAL_DOCTOR.doctorDegrees, action) => {
    switch(action.type){
        case FETCH_DOCTOR_DEGREE_SUCCESS:
            return { ...state, data: action.doctorDegrees };
        default :
            return state;
    }
};
export const doctorAcademicReducer = (state = INITIAL_DOCTOR.doctorAcademics, action) => {
    switch(action.type){
        case FETCH_DOCTOR_ACADEMIC_SUCCESS:
            return { ...state, data: action.doctorAcademics };
        default :
            return state;
    }
};