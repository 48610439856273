import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';
import { authReducer, userReducer, profilesReducer } from './auth-reducer';
import { cityReducer } from './city-reducer';
import { specialistReducer } from './specialist-reducer';
import { surveyReducer } from './survey-reducer';
import { refCodeReducer } from './ref-code-reducer';
import { doctorTitleReducer, doctorDegreeReducer, doctorAcademicReducer } from './doctor-reducer';
import { checkResetPassword } from './auth-reducer';

export const init = () =>{
    const reducer = combineReducers({
        cities: cityReducer,
        specialists: specialistReducer,
        form: formReducer,
        auth: authReducer,
        user: userReducer,
        profiles: profilesReducer,
        surveys: surveyReducer,
        doctorTitles: doctorTitleReducer,
        doctorDegrees: doctorDegreeReducer,
        doctorAcademics: doctorAcademicReducer,
        checkResetPassword: checkResetPassword,
        refCode: refCodeReducer
    });

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(reducer,  composeEnhancers(applyMiddleware(thunk))); 
    return store;
}
