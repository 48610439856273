import {
    CLEAR_REFCODE,
    CHECK_REFCODE_REQUEST,
    CHECK_REFCODE_SUCCESS,
    CHECK_REFCODE_FAILURE
} from '../actions/types';

const INITIAL_REFCODE = {
    isValid: false,
    codeType: undefined,
    code: undefined,
    error: undefined,
    isLoading: undefined
}

export const refCodeReducer = (state = INITIAL_REFCODE, action) => {
    switch(action.type){
        case CHECK_REFCODE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case CHECK_REFCODE_SUCCESS:
            return {
                ...state,
                code: action.refCode.code,
                codeType: action.refCode.code_type,
                isLoading: false,
                isValid: true,
                error: undefined
            }
        case CHECK_REFCODE_FAILURE:
            return {
                ...state,
                code: undefined,
                codeType: undefined,
                isLoading: false,
                error: action.error,
                isValid: false
            }
      case CLEAR_REFCODE:
        return{
          ...state,
          ...INITIAL_REFCODE
        }
        default:
            return state;
    }
}

