import React from "react";
import Auth  from "../components/auth/Auth";

export default class AuthPage extends React.Component {
  render() {
    return (
      <section id="auth" className="container mt-12">
        
        <Auth />
      </section>
    );
  }
}
