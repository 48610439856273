import {
  CLEAR_USER_DATA,
    LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  FETCH_USER_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  RESET_PROFILE_ERROR,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAIL,
  UPDATE_DOCTOR_PROFILE_SUCCESS,
  UPDATE_DOCTOR_PROFILE_FAIL,
  FETCH_STATUS_RESET_PASSWORD
} from "../actions/types";

const INITAL_USER = {
  auth: {
    isAuth: false,
    errors: [],
    email: "",
      fullname: "",
      isLoading: false
  },
  user: {
    data: {},
    error: undefined
  },
  profiles: {
    data: [],
    error: undefined
  },
  checkResetPassword:{
    status: {}
  }
};

export const authReducer = (state = INITAL_USER.auth, action) => {
  switch (action.type) {
      case LOGIN_REQUEST:
          return {
              ...state,
              isLoading: true
          }
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isAuth: true,
          isLoading: false,
        errors: [],
        email: action.email,
        fullname: action.fullname
      });
    case LOGIN_FAILURE:
          return Object.assign({}, state, { 
              isLoading: false,
              errors: action.errors 
          });
    case LOGOUT:
      return Object.assign({}, state, { isAuth: false, email: "", fullname: "" });
    default:
      return state;
  }
};

export const userReducer = (state = INITAL_USER.user, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return Object.assign({}, state, {data : action.user});
    case UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state, {data : action.user});
    case UPDATE_PROFILE_FAIL:
      return Object.assign({}, state, {error : action.error});
    case UPDATE_DOCTOR_PROFILE_SUCCESS:
      return Object.assign({}, state, {data : action.user});
    case UPDATE_DOCTOR_PROFILE_FAIL:
      return Object.assign({}, state, {error : action.error});
    case RESET_PROFILE_ERROR:
      return Object.assign({}, state, {error : undefined});
    case CLEAR_USER_DATA:
      return {
        ...state,
        data: {},
        error: undefined
      }
    default:
      return state;
  }
};

export const profilesReducer = (state = INITAL_USER.profiles, action) => {
  switch(action.type){
    case FETCH_PROFILE_SUCCESS: 
      return Object.assign({}, state, {data: action.profiles});
    case FETCH_PROFILE_FAIL:
      return Object.assign({}, state, {error: action.error});
    default:
      return state;
  }
}

export const checkResetPassword = (state = INITAL_USER.checkResetPassword, action) => {
  switch(action.type){
    case FETCH_STATUS_RESET_PASSWORD:
      return Object.assign({}, state, { status: action.status });
    default: return state;
  }
}
