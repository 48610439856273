import { FETCH_SURVEY_SUCCESS } from "../actions/types";

const INITIAL_SURVEYS = {
  surveys: {
    data: []
  }
};

export const surveyReducer = (state = INITIAL_SURVEYS.surveys, action) => {
  switch (action.type) {
    case FETCH_SURVEY_SUCCESS:
      return { ...state, data: action.surveys };
    default:
      return state;
  }
};
