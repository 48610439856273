import React from "react";

export const DPSelect = ({
  input,
  label,
  options,
  className,
  meta: { touched, error, warning }
}) => {
    function renderOptions() {
        return options.map((option, index) => {
          return <option key={index} value={option._id}> {option.name} </option>
        });
      }
  return (
    <div className="form-group mb-2">
      <label>{label}</label>
      <div className="input-group">
        <select {...input} className={className}>
          {renderOptions()}
        </select>
      </div>

      {touched &&
          ((error && <div className='dp-validate'>{error}</div>))}
    </div>
  );
};
