 import React from "react";
import { withRouter } from "react-router-dom";
import LockUser from "../components/LockUser/LockUser";


class LockUserPage extends React.Component{
    render(){
        let token = this.props.match.params.token;
        return(
            <section id="lockUser" className="container mt-5">
            <LockUser token={token}></LockUser>
            </section>
        );
    }
}

export default withRouter(LockUserPage);