import { FETCH_DOCTOR_TITLE_SUCCESS,
  FETCH_DOCTOR_DEGREE_SUCCESS,
  FETCH_DOCTOR_ACADEMIC_SUCCESS } from './types';
import axiosService from '../services/axios.service';

const axiosInstance = axiosService.getBaseInstance();

const fetchDoctorTitleSuccess = (doctorTitles) => {
    return {
        type: FETCH_DOCTOR_TITLE_SUCCESS,
        doctorTitles
    }
}

const fetchDoctorDegreeSuccess = (doctorDegrees) => {
  return {
      type: FETCH_DOCTOR_DEGREE_SUCCESS,
      doctorDegrees
  }
}

const fetchDoctorAcademicSuccess = (doctorAcademics) => {
  return {
      type: FETCH_DOCTOR_ACADEMIC_SUCCESS,
      doctorAcademics
  }
}

export const fetchDoctorTitles = () => {
    return (dispatch) => {
      axiosInstance.get('/doctorTitles').then(res => res.data).then((doctorTitles) => {
        dispatch(fetchDoctorTitleSuccess(doctorTitles));
      });
    }
  };
  
export const fetchDoctorDegrees = () => {
  return (dispatch) => {
    axiosInstance.get('/doctorDegrees').then(res => res.data).then((doctorDegrees) => {
      dispatch(fetchDoctorDegreeSuccess(doctorDegrees));
    });
  }
};


export const fetchDoctocAcademics = () => {
  return (dispatch) => {
    axiosInstance.get('/doctorAcademics').then(res => res.data).then((doctorAcademics) => {
      dispatch(fetchDoctorAcademicSuccess(doctorAcademics));
    });
  }
};

