import React from "react";
import Terms from "../components/terms/terms";
import Banner from "../components/shared/Banner";
import imageTerm from "../assets/terms.jpg";
export default class TermsPage extends React.Component {
  componentWillMount(){
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  
  render() {
    const content = "Khi tham gia vào một cuộc khảo sát nghiên cứu thị trường của Cimigo Health bạn đồng ý tuân thủ các điều khoản, điều kiện và hướng dẫn trong các Điều Khoản và Quy Định này.";
    return (
      <React.Fragment>
        <Banner image={imageTerm} 
        image_class="terms"
        title="Điều khoản & điều kiện"
        content={content}/>
        <section id="terms" className="container">
          <Terms />
        </section>
      </React.Fragment>
    );
  }
}
