import React from "react";
import { Field, reduxForm } from "redux-form";
import { DPInput } from "../shared/form/DPInput";

const ForgotPasswordForm = props => {
  const { handleSubmit, submitCb, indicator } = props;
  return (
    <form onSubmit={handleSubmit(submitCb)}>
      <Field name="username" component={DPInput} label="Email hoặc số điện thoại" type="text" className="material-ui-form" />
     
      <button
        className="action-btn blue register-btn btn-block mt-4"
        type="submit"
        disabled={indicator}
      >
        Xác nhận
      </button>
    </form>
  );
};

const validate = values => {
    const errors = {}
    const requiredFields = [
      'username',
    ]
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Không được bỏ trống'
      }
    })
    // if (
    //   values.email &&
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    // ) {
    //   errors.email = 'Địa chỉ email không hợp lệ'
    // }
    return errors
  }
  
export default reduxForm({
  form: "ForgotPasswordForm",
  validate
})(ForgotPasswordForm);
