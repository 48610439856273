import React from "react";
import SurveyGizmo from "../components/redirect/survey-gizmo/survey-gizmo";

export default class RedirectSurveyGizmoPage extends React.Component{
    render(){
        let externalId = this.props.match.params.externalId;
        return(
            <section id="survey-gizmo" className="container mt-10">
                <SurveyGizmo externalId={externalId}></SurveyGizmo>
            </section>
        );
    }
}