import React from "react";
export default function(props) {
  const { title, content, className, image_class } = props;
  let img_class = "";
  if(image_class) img_class=image_class;


  return (
    <section id="banner">
      <div className={`image-intro ${img_class}`}>
      </div>
      <div className="intro-content">
        <div className="wrapper ">
          <div className="wrapper-content">
            <h1 className={className}>{title}</h1>
            <p>{content}</p>

          </div>
        </div>
      </div>
    </section>
  );
}
