import React from "react";
import SurveyTable from "./SurveyTable";
import Content from "./Content";
import { connect } from "react-redux";
import * as actionAuth from "../../actions/auth-action";
import * as actionSurvey from "../../actions/survey-action";

import NumberFormat from 'react-number-format';

class Dashboard extends React.Component {
  constructor(){
    super();

    this.state = {
      deny: {
        id: undefined,
        loading: false
    
      }
    }
    this.surveyDeny = this.surveyDeny.bind(this);
  }
  componentWillMount() {
    this.props.dispatch(actionAuth.getUser());
    this.props.dispatch(actionSurvey.fetchSurveys());
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.surveys !== this.props.surveys){
      this.setState({ 
        deny:{
          loading: false,
          id: undefined
        }
       });
    }
  }

  surveyDeny(surveyId){
    this.setState({ 
      deny:{
        loading: true,
        id: surveyId
      }
     });
    this.props.dispatch(actionSurvey.surveyDeny(surveyId));
  }

  surveyJoin(surveyId, link){
    actionSurvey.surveyJoin(surveyId).then(
      data => {
        window.location = link;
      });
  }
  render() {
    const { user, surveys } = this.props;

    if (user._id) {
      return (
        <div className="mt-8 mb-5">
        <Content
              isCompleteProfile={user.is_complete_profile}
              isUplodatedLicense={user.is_uplodated_license}
              externalId={user.external_id}
            />
          <h3 className='uppercase'>danh sách khảo sát</h3>
          <SurveyTable 
            surveys={surveys} 
            userSurveys={user.surveys} 
            user={user} 
            deny={this.state["deny"]}
            functionDeny={this.surveyDeny}
            functionJoin={this.surveyJoin}
          />

          <div className="compensation-content">
                <h3>
                  Thu nhập trong năm {new Date().getFullYear()}
                  &nbsp;&nbsp;&nbsp;
                  <span className="label label-primary compensation-value"><NumberFormat value={user.compensation} displayType={'text'} thousandSeparator={true}/> VNĐ</span>
                </h3>
              </div>
        </div>
      );
    } else {
      return (
        <div className="mt-8 mb-5">
          <div className="text-center">
            <h1>Vui lòng chờ...</h1>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    surveys: state.surveys.data
  };
}

export default connect(mapStateToProps)(Dashboard);
