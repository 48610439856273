import React from "react";
import ChangePasswordForm from "./ChangePasswordForm";
import * as actionAuth from "../../actions/auth-action";
import { destroy, isPristine } from "redux-form";
import { connect } from "react-redux";

import SnackBar from "../shared/snackBar";

class ChangePassword extends React.Component {
  constructor() {
    super();

    this.state = {
      snackBar: {
        message: "",
        variant: "",
        open: false
      }
    };

    this.changePassword = this.changePassword.bind(this);
    this.cancelSnackBar = this.cancelSnackBar.bind(this);
  }

  cancelSnackBar = () => {
    this.setState({
      snackBar: {
        ...this.state.snackBar,
        open: false
      }
    });
  };

  changePassword(userData) {
    const data = {
      password: userData.password,
      newPassword: userData.newPassword
    };
    actionAuth.changePassword(data).then(
      res => {
        this.props.destroy("ChangePasswordForm");
        this.setState({
          snackBar: {
            message: "Đổi mật khẩu thành công",
            variant: "success",
            open: true
          }
        });
      },
      error => {
        this.setState({
          snackBar: {
            message: error,
            variant: "error",
            open: true
          }
        });
      }
    );
  }

  render() {
    
    return (
      <div className="text-center mt-10 mb-5">
        <div className="col-md-4 offset-4">
          <h3 className="uppercase">đổi mật khẩu</h3>

          <div className="text-left">
            <ChangePasswordForm submitCb={this.changePassword} />
          </div>
          <div className="text-left">
            <SnackBar
              message={this.state.snackBar.message}
              variant={this.state.snackBar.variant}
              open={this.state.snackBar.open}
              handleCloseCb={this.cancelSnackBar}
            />
            
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ChangePasswordForm: state.form.ChangePasswordForm,
    pristine: isPristine("ChangePasswordForm")(state)
  };
};

export default connect(
  mapStateToProps,
  { destroy }
)(ChangePassword);
