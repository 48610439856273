import React from 'react';
import {Redirect} from 'react-router-dom';

import _isEmpty from 'lodash/isEmpty';

import RegisterSection from '../register/RegisterSection';

class AdditionalInfo extends React.Component {

  render() {
    const {user, ref_code, isAuth} = this.props;
    if(!isAuth){
      return <Redirect to="/" />
    }
    if (!_isEmpty(user.doctor_profile)) {
      return <Redirect to="/bang-dieu-khien" />    
    }
    return (
      <div id="register-section">
        <RegisterSection ref_code={ref_code} />
      </div>
    );
  }
}

export default AdditionalInfo;
