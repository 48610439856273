import React from 'react';
import {connect} from 'react-redux';
import * as actionAuth from '../../actions/auth-action';

import {EditableInput} from '../shared/editable/EditableInput';
import {EditableSelect} from '../shared/editable/EditableSelect';

import * as actionUser from '../../actions/user-action';
import * as actionCity from '../../actions/city-action';
import * as actionSpecialist from '../../actions/specialist-action';
import * as actionDocor from '../../actions/doctor-action';

import {ProfileAvatar} from './ProfileAvatar';
import {get_avatar_url} from '../../helpers/url.helpers';
import SnackBar from '../shared/snackBar';

class Profile extends React.Component {
  constructor() {
    super();

    this.state = {
      fullname: false,
      phone: false,
      hospital_address: false,
      specialist: false,
      city: false,
      benh_vien: false,
      khoa_lam_viec: false,
      nam_kinh_nghiem: false,
      benh_nhan: false,
      hoc_vi: false,
      chuc_danh: false,
      hoc_ham: false,
      modal: {
        open: false,
      },
      upload: {
        pending: false,
        isActive: false,
        status: '',
        error: '',
      },
      isDisableLockUser: false,
      snackBar: {
        message: '',
        variant: '',
        open: false,
      },
    };
    this.updateProfile = this.updateProfile.bind(this);
    this.updateDoctorProfile = this.updateDoctorProfile.bind(this);

    this.uploadImage = this.uploadImage.bind(this);
    this.resetProfileError = this.resetProfileError.bind(this);
    this.confirmLockUser = this.confirmLockUser.bind(this);

    this.openModal = this.openModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.cancelSnackBar = this.cancelSnackBar.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(actionAuth.getUser());
    this.props.dispatch(actionCity.fetchCities());
    this.props.dispatch(actionSpecialist.fetchSpecialists());
    this.props.dispatch(actionDocor.fetchDoctorTitles());
    this.props.dispatch(actionDocor.fetchDoctorDegrees());
    this.props.dispatch(actionDocor.fetchDoctocAcademics());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.setState({
        fullname: false,
        hospital_address: false,
        specialist: false,
        city: false,
        phone: false,
      });
    }
    if (nextProps.user.doctor_profile !== this.props.user.doctor_profile) {
      this.setState({
        benh_vien: false,
        khoa_lam_viec: false,
        nam_kinh_nghiem: false,
        benh_nhan: false,
        hoc_vi: false,
        chuc_danh: false,
        hoc_ham: false,
      });
    }
  }

  cancelModal() {
    this.setState({
      modal: {open: false},
    });
  }

  openModal() {
    this.setState({
      modal: {open: true},
    });
  }

  updateProfile(profileData, type, entityField) {
    const userData = {
      data: profileData,
      type: type,
    };
    this.setState({[entityField]: true});
    this.props.dispatch(actionUser.updateProfile(userData));
  }

  updateDoctorProfile(data, type, entityField) {
    const userData = {
      entityField: entityField,
      answer: data[entityField],
    };
    this.setState({[entityField]: true});
    this.props.dispatch(actionUser.updateDoctorProfile(userData));
  }

  uploadImage(userData) {
    this.props.dispatch(actionUser.updateProfile(userData));
  }

  resetProfileError(entityField) {
    this.props.dispatch(actionUser.resetProfileError());
    this.setState({
      [entityField]: false,
    });
  }

  confirmLockUser() {
    this.setState({
      isDisableLockUser: true,
    });
    actionAuth.confirmLockUser().then(
      res => {
        this.setState({
          snackBar: {
            message: `Hệ thống đã gửi link xác nhận hủy đăng ký vào email. 
          Vui lòng kiểm tra email.`,
            variant: 'success',
            open: true,
          },
        });
        this.setState({
          isDisableLockUser: false,
        });
      },
      error => {
        this.setState({
          snackBar: {
            message: error,
            variant: 'error',
            open: true,
          },
        });
        this.setState({
          isDisableLockUser: false,
        });
      },
    );
  }

  cancelSnackBar = () => {
    this.setState({
      snackBar: {
        ...this.state.snackBar,
        open: false,
      },
    });
  };

  render() {
    const {
      user,
      cities,
      specialists,
      error,
      doctorTitles,
      doctorDegrees,
      doctorAcademics,
    } = this.props;
    const {message, variant, open} = this.state.snackBar;
    const {isDisableLockUser} = this.state;

    if (user._id) {
      return (
        <React.Fragment>
          <div className="container bootstrap snippet mt-3 mb-5">
            <div className="row">
              <div className="col-sm-3">
                <div className="text-center">
                  <ProfileAvatar
                    avatar={get_avatar_url(user.avatar)}
                    updateEntity={this.uploadImage}
                  />
                </div>
              </div>

              <div className="col-sm-9">
                <div className="tab-content ml-1">
                  {!user.doctor_profile && (
                    <div className="tab-pane active" id="home">
                      <h4>Đang chờ cập nhật thông tin</h4>
                    </div>
                  )}
                  {user.doctor_profile && (
                    <div className="tab-pane active" id="home">
                      <h4>
                        <EditableInput
                          type="doctorProfile"
                          entity={user.doctor_profile}
                          entityField={'fullname'}
                          className="profile-title"
                          isLoading={this.state['fullname']}
                          updateEntity={this.updateProfile}
                          error={error}
                          resetError={this.resetProfileError}
                        />
                      </h4>
                      { user.doctor_profile['specialist'] && 
                      <span className="specialist">
                        <EditableSelect
                          type="doctorProfile"
                          entity={user.doctor_profile.specialist}
                          entityField={'_id'}
                          entityFielExt={'specialist'}
                          fieldName={user.doctor_profile.specialist.name}
                          className="profile-type"
                          label="Chuyên khoa: "
                          options={specialists}
                          isLoading={this.state['specialist']}
                          updateEntity={this.updateProfile}
                        />
                      </span>
                      }
                      <hr />
                      <p className="profile-info">Thông tin liên hệ</p>
                      <div className="mb-3">
                        <strong>Email: </strong> {user.email}
                      </div>
                      <EditableInput
                        type="user"
                        entity={user.doctor_profile}
                        entityField={'phone'}
                        className="profile-name"
                        label="Số điện thoại:"
                        isLoading={this.state['phone']}
                        updateEntity={this.updateProfile}
                        error={error}
                        resetError={this.resetProfileError}
                      />
                      { user.doctor_profile['city'] && 
                      <EditableSelect
                        type="doctorProfile"
                        entity={user.doctor_profile['city']}
                        entityField={'_id'}
                        entityFielExt={'city'}
                        fieldName={user.doctor_profile['city']['name']}
                        className="profile-type"
                        label="Tỉnh/Thành phố:"
                        options={cities}
                        isLoading={this.state['city']}
                        updateEntity={this.updateProfile}
                      />
                      }
                      <hr />
                      {/* hiển thị thông tin cơ bản của user */}
                      {/* ******************************************************************************************************* */}
                      <p className="profile-info">thông tin cơ bản</p>
                      <EditableInput
                        type="doctorProfile"
                        entity={user.doctor_profile}
                        entityField={'benh_vien'}
                        className="profile-name"
                        label="Nơi làm việc:"
                        isLoading={this.state['benh_vien']}
                        updateEntity={this.updateDoctorProfile}
                        error={error}
                        resetError={this.resetProfileError}
                      />
                      <EditableInput
                        type="doctorProfile"
                        entity={user.doctor_profile}
                        entityField={'khoa_lam_viec'}
                        className="profile-name"
                        label="Khoa làm việc:"
                        isLoading={this.state['khoa_lam_viec']}
                        updateEntity={this.updateDoctorProfile}
                        error={error}
                        resetError={this.resetProfileError}
                      />
                      { user.doctor_profile['chuc_danh'] && 
                      <EditableSelect
                        type="doctorProfile"
                        entity={user.doctor_profile['chuc_danh']}
                        entityField={'_id'}
                        entityFielExt={'chuc_danh'}
                        fieldName={user.doctor_profile['chuc_danh']['name']}
                        className="profile-type"
                        label="Chức danh:"
                        options={doctorTitles}
                        isLoading={this.state['chuc_danh']}
                        updateEntity={this.updateDoctorProfile}
                      />
                      }
                      { user.doctor_profile['hoc_ham'] && 
                      <EditableSelect
                        type="doctorProfile"
                        entity={user.doctor_profile['hoc_ham']}
                        entityField={'_id'}
                        entityFielExt={'hoc_ham'}
                        fieldName={user.doctor_profile['hoc_ham']['name']}
                        className="profile-type"
                        label="Học hàm:"
                        options={doctorAcademics}
                        isLoading={this.state['hoc_ham']}
                        updateEntity={this.updateDoctorProfile}
                      />
                      }
                      { user.doctor_profile['hoc_vi'] && 
                      <EditableSelect
                        type="doctorProfile"
                        entity={
                          user.doctor_profile !== null &&
                          user.doctor_profile['hoc_vi'] !== null
                            ? user.doctor_profile['hoc_vi']
                            : null
                        }
                        entityField={'_id'}
                        entityFielExt={'hoc_vi'}
                        fieldName={
                          user.doctor_profile !== null &&
                          user.doctor_profile['hoc_vi'] !== null &&
                          user.doctor_profile['hoc_vi']['name'] !== null
                            ? user.doctor_profile['hoc_vi']['name']
                            : null
                        }
                        className="profile-type"
                        label="Học vị:"
                        options={doctorDegrees}
                        isLoading={this.state['hoc_vi']}
                        updateEntity={this.updateDoctorProfile}
                      />
                      }

                      { user.doctor_profile['nam_kinh_nghiem'] && 
                      <EditableInput
                        type="doctorProfile"
                        entity={user.doctor_profile}
                        entityField={'nam_kinh_nghiem'}
                        className="profile-name"
                        label="Số năm kinh nghiệm:"
                        isLoading={this.state['nam_kinh_nghiem']}
                        updateEntity={this.updateDoctorProfile}
                        error={error}
                        resetError={this.resetProfileError}
                      />
                      }
                      <EditableInput
                        type="doctorProfile"
                        entity={user.doctor_profile}
                        entityField={'benh_nhan'}
                        className="profile-name"
                        label="Số bệnh nhân trung bình mỗi tháng:"
                        isLoading={this.state['benh_nhan']}
                        updateEntity={this.updateDoctorProfile}
                        error={error}
                        resetError={this.resetProfileError}
                      />
                      <hr />
                      {/* <a
                      href={`https://www.surveygizmo.com/s3/4756956/4cf76ccfeae5?notAnswered=Q1_Q2_Q3_Q4_Q5_Q6&external_id=${
                        user.external_id
                      }`}
                      className="btn btn-dp-primary btn-profile"
                    >
                      Cập nhật thông tin cơ bản
                    </a> */}
                      &nbsp;
                      <button
                        className="btn btn-danger"
                        onClick={() => this.confirmLockUser()}
                        disabled={isDisableLockUser}>
                        {isDisableLockUser && (
                          <div className="sp sp-circle profile-sp" />
                        )}
                        {!isDisableLockUser && 'Hủy đăng ký'}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <SnackBar
            message={message}
            variant={variant}
            open={open}
            handleCloseCb={this.cancelSnackBar}
          />
        </React.Fragment>
      );
    } else {
      return (
        <div className="mt-8 mb-5">
          <div className="text-center">
            <h2>Vui lòng chờ...</h2>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    error: state.user.error,
    cities: state.cities.data,
    specialists: state.specialists.data,
    doctorTitles: state.doctorTitles.data,
    doctorDegrees: state.doctorDegrees.data,
    doctorAcademics: state.doctorAcademics.data,
  };
}

export default connect(mapStateToProps)(Profile);
