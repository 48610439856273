import React from "react";
import Privacy from "../components/privacy/privacy";
import Banner from "../components/shared/Banner";
import imagePravicy from "../assets/privacy.jpg"
export default class PrivacyPage extends React.Component {
  componentWillMount(){
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  
  render() {
    const content = "Với việc tôn trọng quyền riêng tư của cá nhân, Cimigo sẽ không bao giờ chia sẻ, trao đổi hoặc bán thông tin của thành viên cho một bên thứ ba nào khác trong bất kỳ trường hợp nào.";
    return (
      <React.Fragment>
         <Banner image_class="privacy" image={imagePravicy} title="Chính sách bảo mật" content={content} className="h1-title"/>
        <section id="privacy" className="container">
        <Privacy />
      </section>
      </React.Fragment>
      
    );
  }
}
