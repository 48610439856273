import React from "react";
import { Field, reduxForm } from "redux-form";
import { DPInputSimple } from "../shared/form/DPInput";
import { DPTextAreaSimple } from "../shared/form/DPTextArea";

const ContactForm = props => {
  const { handleSubmit, submitCb } = props;
  return (
    <form onSubmit={handleSubmit(submitCb)} className="form-inlin justify-content-center">
      <Field
        name="title"
        component={DPInputSimple}
        type="text"
        label="Tiêu đề"
        placeholder="Nhập Tiêu đề"
        className="form-control"
      />

      <Field
        name="name"
        component={DPInputSimple}
        type="text"
        label="Họ tên"
        placeholder="Nhập Họ tên"
        className="form-control"
      />

      <Field
        name="email"
        component={DPInputSimple}
        type="email"
        label="Email"
        placeholder="Nhập Email"
        className="form-control"
      />

      <Field
        name="message"
        component={DPTextAreaSimple}
        type="text"
        rows="4"
        label="Nội dung"
        placeholder="Nhập Nội dung ...."
        className="form-control"
      />

      <div>
        <button type="submit" className='action-btn blue register-btn btn-block mt-4'>
          Gửi
        </button>
      </div>
    </form>
  );
};

const validate = values => {
  const errors = {};

  if(!values.title){
    errors.title = 'Tiêu đề không được bỏ trống';
  }

  if(!values.name){
    errors.name = 'Họ tên không được bỏ trống';
  }

  if(!values.email){
    errors.email = 'Email không được bỏ trống';
  }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
    errors.email = 'Email không hợp lệ';
  }

  if(!values.message){
    errors.message = "Nội dung không được bỏ trống"
  }

  return errors;
}
export default reduxForm({
  form: "ContactForm",
  validate
})(ContactForm);
