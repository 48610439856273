import { FETCH_CITIES_SUCCESS } from './types';
import axiosService from '../services/axios.service';

const axiosInstance = axiosService.getBaseInstance();

const fetchCitiesSuccess = (cities) => {
    return {
        type: FETCH_CITIES_SUCCESS,
        cities
    }
}

export const fetchCities = () => {
    return (dispatch) => {
      axiosInstance.get('/cities').then(res => res.data).then((cities) => {
        dispatch(fetchCitiesSuccess(cities));
      });
    }
  };
