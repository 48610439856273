import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import * as actionAuth from '../../actions/auth-action';

// Components
import ZaloAuth from './ZaloAuth';

class AuthContainer extends React.Component {
    render(){
        let redirect_url = this.props.redirect_url?this.props.redirect_url : "/bang-dieu-khien";
        if(this.props.is_logged_in){
            
            return <Redirect to={redirect_url} />
        }

        const params = new URLSearchParams(this.props.location.search);
        const uid = params.get('uid');
        const code = params.get('code');
        let state = params.get('state');
        if(state === 'undefined') state = undefined;

        return(
            <ZaloAuth isLoading={this.props.isLoading} errors={this.props.errors} redirect_url={redirect_url} onLogin={this.props.login} uid={uid} oauth_code={code} state={state} />
        );
    }
}

const actions_to_props = {
    login: actionAuth.socialLogin
}

function map_state_to_props(state){
    return {
        is_logged_in: state.auth.isAuth,
        errors: state.auth.errors,
        isLoading: state.auth.isLoading
    }
}

export default withRouter(connect(map_state_to_props, actions_to_props)(AuthContainer));

