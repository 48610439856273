import React, { Component } from "react";
import Register from "../register/Register";
import { connect } from "react-redux";

import * as actionCity from "../../actions/city-action";
import * as actionSpecialist from "../../actions/specialist-action";
import * as actionDocor from "../../actions/doctor-action";

class HomeMainSection extends Component {
  constructor(props) {
    super();
  }
  componentWillMount() {
    this.props.dispatch(actionCity.fetchCities());
    this.props.dispatch(actionSpecialist.fetchSpecialists());
    this.props.dispatch(actionDocor.fetchDoctorTitles());
    this.props.dispatch(actionDocor.fetchDoctocAcademics());
  }
  render() {
    const { ref_code } = this.props;
   // console.log(ref_code);
    return (
      <React.Fragment>
        <section className="col-md-6 center-block">
          <Register
            ref_code={ref_code}
            cities={this.props.cities}
            specialists={this.props.specialists}
            doctorTitles={this.props.doctorTitles}
            doctorAcademics={this.props.doctorAcademics}
          />
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cities: state.cities.data,
    specialists: state.specialists.data,
    doctorTitles: state.doctorTitles.data,
    doctorAcademics: state.doctorAcademics.data
  };
}

export default connect(mapStateToProps)(HomeMainSection);
