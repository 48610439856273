import React from "react";
import { Field, reduxForm } from "redux-form";
import DPFileUpload from "../shared/form/DpFileUpload";

const UploadImageForm = () => {
  return(
    <div className="mt-3">
       <Field name="image" label="Image" component={DPFileUpload} />
    </div>
  );
};

export default reduxForm({
  form: "UploadImageForm"
})(UploadImageForm);
