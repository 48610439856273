import {
  CLEAR_REFCODE,
  CLEAR_USER_DATA,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  FETCH_USER_SUCCESS,
  FETCH_STATUS_RESET_PASSWORD,
} from './types';
import authService from '../services/auth-service';
import axiosService from '../services/axios.service';

const axiosInstance = axiosService.getInstance();
const axiosBaseInstance = axiosService.getBaseInstance();

const fetchStatusResetPassword = status => {
  return {
    type: FETCH_STATUS_RESET_PASSWORD,
    status,
  };
};

const loginSuccess = () => {
  const email = authService.getEmail();
  const fullname = authService.getFullname();
  return {
    type: LOGIN_SUCCESS,
    email,
    fullname,
  };
};

const loginFailure = errors => {
  return {
    type: LOGIN_FAILURE,
    errors,
  };
};

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const fetchUserSuccess = user => {
  return {
    type: FETCH_USER_SUCCESS,
    user,
  };
};

export const checkAuthState = () => {
  return dispatch => {
    if (authService.isAuthenticated()) {
      dispatch(loginSuccess());
    }
  };
};

export const login = userData => {
  return dispatch => {
    dispatch(loginRequest());
    return axiosBaseInstance
      .post('/users/login', userData)
      .then(res => res.data)
      .then(token => {
        authService.saveToken(token);
        dispatch(loginSuccess());
      })
      .catch(({response}) => {
        dispatch(loginFailure(response.data.message));
      });
  };
};

export const socialLogin = (info, social) => {
  return dispatch => {
    dispatch(loginRequest());
    switch (social) {
      case 'facebook':
        return axiosBaseInstance
          .post('/users/facebook-login', {
            access_token: info.access_token,
            ref_code: info.ref_code,
          })
          .then(res => res.data)
          .then(token => {
            authService.saveToken(token);
            dispatch(loginSuccess());
          })
          .catch(({response}) => {
            dispatch(loginFailure(response.data.message));
          });
      case 'zalo':
        return axiosBaseInstance
          .post('/users/zalo-login', {
            oauth_code: info.oauth_code,
            ref_code: info.ref_code,
          })
          .then(res => res.data)
          .then(token => {
            authService.saveToken(token);
            dispatch(loginSuccess());
          })
          .catch(({response}) => {
            dispatch(loginFailure(response.data.message));
          });
      case 'google':
        return axiosBaseInstance
          .post('/users/google-login', {
            access_token: info.access_token,
            ref_code: info.ref_code,
          })
          .then(res => res.data)
          .then(token => {
            authService.saveToken(token);
            dispatch(loginSuccess());
          })
          .catch(({response}) => {
            dispatch(loginFailure(response.data.message));
          });
      default:
        break;
    }
  };
};

export const getUser = () => {
  return dispatch => {
    axiosInstance
      .get('/users/info')
      .then(res => res.data)
      .then(user => {
        dispatch(fetchUserSuccess(user));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
    dispatch({
      type: CLEAR_USER_DATA
    });
    dispatch({
      type: CLEAR_REFCODE
    });
    authService.invalidateUser();
  }
};

export const register = userData => {
  return axiosBaseInstance
    .post('/applications', userData)
    .then(res => res.data, err => Promise.reject(err.response.data.message));
};

export const addProfile = userData => {
  return axiosInstance
    .post('/doctorProfiles/add-profile', userData)
    .then(res => res.data, err => Promise.reject(err.response.data.message));
};

export const confirmLockUser = () => {
  return axiosInstance.post('/users/confirm-lock').then(
    res => {
      console.log(res);
      return res.data;
    },
    err => {
      Promise.reject(err.message);
    },
  );
};

export const lockUser = token => {
  return axiosBaseInstance
    .put('/users/lock-user', {token: token})
    .then(res => res.data, err => Promise.reject(err.response.data.message));
};

export const changePassword = userData => {
  return axiosInstance
    .post('/users/change-password', userData)
    .then(res => res.data, err => Promise.reject(err.response.data.message));
};

export const forgotPassword = userData => {
  return axiosBaseInstance
    .post('/users/forgot-password', userData)
    .then(res => res, err => Promise.reject(err.response.data.message));
};

export const checkOTP = userData => {
  return axiosBaseInstance
    .post('/user-code/check-otp', userData)
    .then(res => res.data, err => Promise.reject(err.response.data.message));
};

export const checkResetPassword = data => {
  return dispatch =>
    axiosBaseInstance.post('/users/check-reset-password', data).then(
      res => {
        dispatch(fetchStatusResetPassword(res.data));
      },
      err => Promise.reject(err.response.data.message),
    );
};

export const resetPassword = userData => {
  return axiosBaseInstance
    .put('/users/reset-password', userData, {
      headers: {Authorization: `Bearer ${userData.token}`},
    })
    .then(
      res => res.data,
      err => {
        Promise.reject(err.response.data);
      },
    );
};
