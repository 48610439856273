import React from  "react";

export class EditableComponent extends React.Component{
    constructor(){
        super();

        this.state = {
            isActive: false,
            value: undefined,
            originValue: undefined,
        }

        this.handlChange = this.handlChange.bind(this);
    }

    componentDidUpdate(){
        const { error , resetError, entityField } = this.props;
        if(error){
           this.setOriginValue();
           resetError(entityField);
        }
    }

    componentDidMount(){
        this.setOriginValue();
        
    }

    setOriginValue(){
        const { entity, entityField } = this.props;

        var _value = undefined;
        
        if(entity === undefined || entity === null) {
             _value = ''; 
        }
        else{
            if(entityField === 'nam_kinh_nghiem'){
                var date = new Date();
                _value = entity[entityField] ? (date.getFullYear() - entity[entityField]) : 0;
            }else if(entityField === 'benh_nhan'){
                _value = entity[entityField] ? entity[entityField] : 0;
            }else{
                _value = entity[entityField] ? entity[entityField] : '';
            }
        };

        this.setState({
            value: _value,
            originValue: _value,
            isActive: false
        });

       
    }

    disableEdit(){
        const { originValue } = this.state;
        this.setState({isActive: false, value: originValue});
    }

    enableEdit(){
        this.setState({isActive: true});
    }

    update(){
        const {value, originValue} = this.state;
        const { updateEntity, entityField, type, entityFielExt } = this.props;

        if(value !== originValue){
            const field = entityFielExt === undefined ? entityField : entityFielExt;
            updateEntity({ [entityFielExt===undefined? entityField : entityFielExt] : value}, type, field);
            this.setState({isActive: false, originValue: value});
        }
    }

    handlChange(event){
        this.setState({value: event.target.value});
    }
}
